import type { MetadataItemInstance, MetadataLiteralTypes, StoredObject } from '@nexdynamic/squeegee-common';
import { MetadataCollectionValue, notNullUndefinedEmptyOrZero } from '@nexdynamic/squeegee-common';
import { Data } from '../../../../Data/Data';
import { Logger } from '../../../../Logger';
import { NotifyUserMessage } from '../../../../Notifications/NotifyUserMessage';
import { getMetadataCollectionValue } from '../../getMetadataCollectionValue';

export type SaveItemSchemaFormDataProps = {
    formSpecificDatum: StoredObject | Array<StoredObject> | undefined;
    targetObjectId: string;
    collectionSchemaId: string;
    values: MetadataItemInstance<MetadataLiteralTypes>;
    itemSchemaKey: number;
    setSelectedCollectionValue: (collectionValue: MetadataCollectionValue) => void;
};

export type SaveFormValues = Pick<SaveItemSchemaFormDataProps, 'values' | 'formSpecificDatum'>;

export const saveItemFormValues = async ({
    formSpecificDatum,
    targetObjectId,
    collectionSchemaId,
    values,
    itemSchemaKey,
    setSelectedCollectionValue,
}: SaveItemSchemaFormDataProps) => {
    if (!values) return;

    try {
        if (formSpecificDatum !== undefined) {
            const arrayData = Array.isArray(formSpecificDatum) ? formSpecificDatum : [formSpecificDatum];
            arrayData.length && (await Promise.all(arrayData.filter(notNullUndefinedEmptyOrZero).map(d => Data.put(d))));
        }

        let collectionValue: MetadataCollectionValue | undefined = undefined;
        collectionValue = getMetadataCollectionValue({
            targetObjectId: targetObjectId,
            valuesSchemaId: collectionSchemaId,
        });

        if (!collectionValue) {
            collectionValue = new MetadataCollectionValue(targetObjectId, collectionSchemaId);
            MetadataCollectionValue.addMetadataItemValue({
                metadataCollectionValue: collectionValue,
                indexKey: itemSchemaKey,
                notes: values.notes,
                value: values.value,
                skipped: values.skipped,
            });
            setSelectedCollectionValue(collectionValue);
        } else {
            MetadataCollectionValue.addMetadataItemValue({
                metadataCollectionValue: collectionValue,
                indexKey: itemSchemaKey,
                notes: values.notes,
                value: values.value,
                skipped: values.skipped,
            });
        }

        if (collectionValue) {
            await Data.put(collectionValue);
        }
    } catch (error) {
        new NotifyUserMessage('custom-fields.saving-form-values-failed');
        Logger.error('Error saving item schema form values', error);
    }
};
