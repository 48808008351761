import type { MetadataItemInstance, MetadataItemSchema, MetadataLiteralTypes } from '@nexdynamic/squeegee-common';
import type { SaveFormValues } from '../saveItemFormValues';
import { SelectMetadataItemForm } from './array-value-forms/SelectMetadataItemForm';
import { CheckboxMetadataItemForm } from './boolean-value-forms/CheckboxMetadataItemForm';
import { isAttachmentMetadataSchema } from './completed-forms/completed-form-helpers/isAttachmentMetadataSchema';
import { isBooleanMetadataInstance } from './completed-forms/completed-form-helpers/isBooleanMetadataInstance';
import { isBooleanMetadataSchema } from './completed-forms/completed-form-helpers/isBooleanMetadataSchema';
import { isNumberMetadataInstance } from './completed-forms/completed-form-helpers/isNumberMetadataInstance';
import { isNumberMetadataSchema } from './completed-forms/completed-form-helpers/isNumberMetadataSchema';
import { isSelectMetadataInstance } from './completed-forms/completed-form-helpers/isSelectMetadataInstance';
import { isSelectMetadataSchema } from './completed-forms/completed-form-helpers/isSelectMetadataSchema';
import { isSignatureMetadataSchema } from './completed-forms/completed-form-helpers/isSignatureMetadataSchema';
import { isStringMetadataInstance } from './completed-forms/completed-form-helpers/isStringMetadataInstance';
import { isTextMetadataSchema } from './completed-forms/completed-form-helpers/isTextMetadataSchema';
import { NumberMetadataItemForm } from './number-value-forms/NumberMetadataItemForm';
import { AttachmentMetadataItemForm } from './string-value-forms/AttachmentMetadataItemForm';
import { SignatureMetadataItemForm } from './string-value-forms/SignatureMetadataItemForm';
import { TextMetadataItemForm } from './string-value-forms/TextMetadataItemForm';

export type MetadataFormProps<T extends MetadataLiteralTypes> = {
    itemSchema: MetadataItemSchema<T>;
    saveValues: ({ formSpecificDatum, values }: SaveFormValues) => Promise<void>;
    skipItem: (values: MetadataItemInstance<MetadataLiteralTypes>) => Promise<void>;
    handleBackClicked: () => void;
    itemInstance?: MetadataItemInstance<T>;
};
export const getFormForMetadata = ({
    handleBackClicked,
    itemSchema,
    saveValues,
    itemInstance,
    skipItem,
}: MetadataFormProps<MetadataLiteralTypes>): JSX.Element => {
    const renderElement = () => {
        if (isSignatureMetadataSchema(itemSchema) && isStringMetadataInstance(itemInstance || {})) {
            return (
                <SignatureMetadataItemForm
                    handleBackClicked={handleBackClicked}
                    itemSchema={itemSchema}
                    saveValues={saveValues}
                    itemInstance={itemInstance}
                    skipItem={skipItem}
                />
            );
        }

        if (isAttachmentMetadataSchema(itemSchema) && isStringMetadataInstance(itemInstance || {})) {
            return (
                <AttachmentMetadataItemForm
                    handleBackClicked={handleBackClicked}
                    itemSchema={itemSchema}
                    saveValues={saveValues}
                    itemInstance={itemInstance}
                    skipItem={skipItem}
                />
            );
        }

        if (isTextMetadataSchema(itemSchema) && isStringMetadataInstance(itemInstance || {})) {
            return (
                <TextMetadataItemForm
                    handleBackClicked={handleBackClicked}
                    itemSchema={itemSchema}
                    saveValues={saveValues}
                    itemInstance={itemInstance}
                    skipItem={skipItem}
                />
            );
        }

        if (isBooleanMetadataSchema(itemSchema) && isBooleanMetadataInstance(itemInstance || {})) {
            return (
                <CheckboxMetadataItemForm
                    handleBackClicked={handleBackClicked}
                    itemSchema={itemSchema}
                    saveValues={saveValues}
                    itemInstance={itemInstance}
                    skipItem={skipItem}
                />
            );
        }

        if (isNumberMetadataSchema(itemSchema) && isNumberMetadataInstance(itemInstance || {})) {
            return (
                <NumberMetadataItemForm
                    handleBackClicked={handleBackClicked}
                    itemSchema={itemSchema}
                    saveValues={saveValues}
                    itemInstance={itemInstance}
                    skipItem={skipItem}
                />
            );
        }

        if (isSelectMetadataSchema(itemSchema) && isSelectMetadataInstance(itemInstance || {})) {
            return (
                <SelectMetadataItemForm
                    handleBackClicked={handleBackClicked}
                    itemSchema={itemSchema}
                    saveValues={saveValues}
                    itemInstance={itemInstance}
                    skipItem={skipItem}
                />
            );
        }

        throw new Error('Unsupported metadata schema' + itemSchema.type);
    };

    return renderElement();
};
