import { InputAdornment, Stack, TextField } from '@mui/material';
import type { MetadataItemInstance, NumberMetadataTypes } from '@nexdynamic/squeegee-common';
import { generateMetadataFormInitialValues } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import { t } from '../../../../../../t';
import { FieldFormHeader } from '../../../dialog/components/FieldFormHeader';
import type { MetadataFormProps } from '../getFormForMetadata';

export const NumberMetadataItemForm: React.FC<MetadataFormProps<NumberMetadataTypes>> = ({
    itemSchema,
    saveValues,
    itemInstance,
    skipItem,
}) => {
    const [values, setValues] = useState<MetadataItemInstance<'number'>>(generateMetadataFormInitialValues(itemSchema.type, itemInstance));

    const handleValueChange = (field: keyof MetadataItemInstance<'number'>) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValues = {
            ...values,
            [field]: field === 'value' ? Number(event.target.value) : event.target.value,
        };

        setValues(newValues);
    };

    return (
        <Stack spacing={2}>
            <FieldFormHeader
                itemSchema={itemSchema}
                itemInstance={itemInstance}
                values={values}
                setValues={setValues}
                skipItem={skipItem}
                saveValues={saveValues}
            />

            <TextField
                label={t('general.value')}
                value={Number(values.value)}
                onChange={handleValueChange('value')}
                InputProps={{
                    startAdornment: itemSchema.settings?.prefix && (
                        <InputAdornment position="start">{itemSchema.settings.prefix}</InputAdornment>
                    ),
                    endAdornment: itemSchema.settings?.suffix && (
                        <InputAdornment position="end">{itemSchema.settings.suffix}</InputAdornment>
                    ),
                    type: 'number',
                }}
            />

            <TextField
                label={t('general.notes')}
                value={values.notes}
                onChange={handleValueChange('notes')}
                multiline
                rows={4}
                inputProps={{ maxLength: 255 }}
            />
        </Stack>
    );
};
