import type { AvailableMetadataTarget, Customer, StoredObject } from '@nexdynamic/squeegee-common';
import { Data } from '../../../../Data/Data';
import { MetadataCollectionService } from '../../MetadataCollectionService';
import { getGlobalSchemaIdsFromType } from '../../getGlobalSchemaIdsFromType';
import { resolveSchemaParentId } from '../../resolveSchemaParentId';
import type { CustomFieldDataStructure, SchemaItem } from '../CustomFieldDialog';

export const buildCustomFieldSchemaStructure = (options: {
    relatedObjectId: string;
    type: AvailableMetadataTarget;
    customerId?: string;
}): {
    schemaDataStructure: CustomFieldDataStructure;
    global: CustomFieldDataStructure;
} => {
    const { relatedObjectId, type, customerId } = options;

    const targetObject = customerId ? Data.get<Customer>(customerId)?.jobs[relatedObjectId] : Data.get<StoredObject>(relatedObjectId);
    if (!targetObject) return { schemaDataStructure: new Map(), global: new Map() };

    const schemaDataStructure: CustomFieldDataStructure = new Map();

    const allSchemas = MetadataCollectionService.getAll();
    if (!allSchemas.length)
        return { schemaDataStructure: new Map() as CustomFieldDataStructure, global: new Map() as CustomFieldDataStructure };

    const attachedSchemas = MetadataCollectionService.fetchAllSchema(resolveSchemaParentId(targetObject));
    const globalSchemaIds = getGlobalSchemaIdsFromType(type);

    const globalSchemas = MetadataCollectionService.getAll({ target: type })
        .concat(MetadataCollectionService.getAll({ target: 'any' }))
        .filter(x => globalSchemaIds.includes(x._id));
    const global: CustomFieldDataStructure = new Map(globalSchemas.map(x => [x._id, { schema: x, isEnabled: true }]));

    for (const schema of allSchemas) {
        if (global.has(schema._id)) continue;

        const isEnabled = attachedSchemas.map(x => x._id).includes(schema._id);
        schemaDataStructure.set(schema._id, { schema, isEnabled });
    }
    return { schemaDataStructure, global };
};

export const buildGlobalSchemaStructure = (type: AvailableMetadataTarget) => {
    if (!type) return new Map<string, SchemaItem>();
    const globalSchemas = getGlobalSchemaIdsFromType(type);
    const allSchemas = MetadataCollectionService.getAll();

    const schemaDataStructure: CustomFieldDataStructure = new Map();

    for (const schema of allSchemas) {
        const isEnabled = globalSchemas.includes(schema._id);
        schemaDataStructure.set(schema._id, { schema, isEnabled });
    }

    return schemaDataStructure;
};
