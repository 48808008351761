import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import type { AvatarProps, ListItemButtonProps, ListItemProps, ListItemTextProps, PaperProps } from '@mui/material';
import { ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Skeleton } from '@mui/material';
import type { ColorOptions } from '@nexdynamic/nex-ui-react';
import { IconAvatar } from '@nexdynamic/nex-ui-react';

type SkeletonProps = {
    skeleton: true;
};

type NonSkeletonProps = {
    skeleton?: false;
    primary: ListItemTextProps['primary'];
    secondary?: ListItemTextProps['secondary'];
    slotProps?: {
        paper?: PaperProps;
        listItem?: ListItemProps;
        listItemButton?: ListItemButtonProps;
        avatarProps?: AvatarProps & { color?: ColorOptions };
    };
    slots?: {
        avatar?: React.ReactNode;
        icon?: React.ReactNode;
        secondaryAction?: React.ReactNode;
        secondaryArea?: React.ReactNode;
    };
    onClick?: () => void;
    disabled?: boolean;
};

type Props = SkeletonProps | NonSkeletonProps;

const ActionableListItem = (props: Props) => {
    const isSkeleton = 'skeleton' in props && props.skeleton;

    const { secondary, slotProps, slots, onClick, disabled } = isSkeleton ? ({} as NonSkeletonProps) : props;

    return (
        <Paper sx={{ overflow: 'hidden' }} elevation={1} {...slotProps?.paper}>
            <ListItem
                secondaryAction={
                    isSkeleton ? (
                        <Skeleton variant="circular" width={18} height={18} />
                    ) : (
                        slots?.secondaryAction || (onClick ? <ChevronRightIcon /> : undefined)
                    )
                }
                disablePadding
                {...slotProps?.listItem}
            >
                <ListItemButton
                    sx={{
                        minHeight: 60,
                        cursor: onClick ? 'pointer' : 'default',
                    }}
                    onClick={onClick ? () => onClick() : undefined}
                    disabled={disabled || isSkeleton}
                    disableTouchRipple={!onClick}
                    {...slotProps?.listItemButton}
                >
                    {(slots?.icon || slots?.avatar) && (
                        <ListItemAvatar sx={{ minWidth: 0, mr: 2 }}>
                            {isSkeleton ? (
                                <Skeleton variant="circular" width={40} height={40} />
                            ) : slots?.avatar ? (
                                slots.avatar
                            ) : (
                                <IconAvatar avatarProps={slotProps?.avatarProps} color={slotProps?.avatarProps?.color ?? 'info'}>
                                    {slots?.icon}
                                </IconAvatar>
                            )}
                        </ListItemAvatar>
                    )}
                    <ListItemText
                        primary={isSkeleton ? <Skeleton variant="text" width={100} /> : props.primary}
                        secondary={isSkeleton ? <Skeleton variant="text" width={250} /> : secondary}
                        sx={{ mr: 4 }}
                    />
                    {slots?.secondaryArea}
                </ListItemButton>
            </ListItem>
        </Paper>
    );
};

export default ActionableListItem;
