import { Stack, TextField, Typography } from '@mui/material';
import { type MetadataItemInstance } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import useTranslation from '../../../../../hooks/useTranslation';
import { useMetadataCompleteContext } from '../../../context/useMetadataContext';
import { FieldFormHeader } from '../../../dialog/components/FieldFormHeader';
import { DoneNotDoneSkippedToggle } from '../completed-forms/CompletedCheckboxField/DoneNotDoneSkippedToggle';
import type { MetadataFormProps } from '../getFormForMetadata';

export const CheckboxMetadataItemForm: React.FC<MetadataFormProps<'boolean'>> = ({ itemSchema, saveValues, itemInstance, skipItem }) => {
    const { t } = useTranslation();

    const { itemSchemaKey } = useMetadataCompleteContext();

    const [values, setValues] = useState<MetadataItemInstance<'boolean'>>(
        itemInstance ?? {
            skipped: false,
            notes: '',
        }
    );

    const handleBooleanChange = (value: boolean) => {
        const itemSchemaKeyAsNumber = Number(itemSchemaKey);
        if (isNaN(itemSchemaKeyAsNumber)) return;

        const newValues = {
            ...values,
            skipped: false,
            value,
        };

        setValues(newValues);
    };

    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValues = {
            ...values,
            notes: event.target.value,
        };

        setValues(newValues);
    };

    return (
        <Stack spacing={2}>
            <FieldFormHeader
                itemSchema={itemSchema}
                itemInstance={itemInstance}
                values={values}
                setValues={setValues}
                skipItem={skipItem}
                saveValues={saveValues}
            />

            {itemSchema.description && <Typography>{itemSchema.description}</Typography>}

            <DoneNotDoneSkippedToggle
                itemSchema={itemSchema}
                value={values.skipped ? 'skipped' : values.value}
                onFalseClick={() => handleBooleanChange(false)}
                onTrueClick={() => handleBooleanChange(true)}
                enableButtonLabels
            />

            <TextField
                label={t('general.notes')}
                value={values.notes}
                onChange={handleNotesChange}
                multiline
                rows={4}
                inputProps={{ maxLength: 255 }}
            />
        </Stack>
    );
};
