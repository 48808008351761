import CheckIcon from '@mui/icons-material/CheckCircleRounded';
import ChevronRight from '@mui/icons-material/ChevronRightRounded';
import WarningIcon from '@mui/icons-material/ErrorRounded';
import InfoIcon from '@mui/icons-material/InfoRounded';
import { Chip, ListItem, ListItemButton, ListItemText, Paper } from '@mui/material';
import { CircularProgressWithLabel } from '@nexdynamic/nex-ui-react';
import type { MetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { useMetadataCompleteContext } from '../../context/useMetadataContext';
import { useMetadataCompleteRouter } from '../CustomFieldsCompletionDialog';

export const CustomFieldCollectionListItem = ({
    schema,
    itemHasValueDictionary,
}: {
    schema: MetadataCollectionSchema;
    itemHasValueDictionary: { [itemSchemaKey: number]: boolean };
}) => {
    const { selectCollectionSchema } = useMetadataCompleteContext();
    const { navigateTo } = useMetadataCompleteRouter();

    const total = Object.keys(schema.items).length;
    const completed = Object.values(itemHasValueDictionary).filter(x => !!x).length;
    const completePercentage = (completed / total) * 100;

    const handleSelectSchema = async (schemaId: string) => {
        await selectCollectionSchema(schemaId);
        navigateTo('item-schema-list');
    };

    return (
        <ListItem key={schema._id} component={Paper} secondaryAction={<ChevronRight />} disablePadding>
            <ListItemButton onClick={async () => await handleSelectSchema(schema._id)}>
                <CircularProgressWithLabel value={completePercentage} sx={{ mr: 2 }} />
                <ListItemText primary={schema.title} secondary={schema.description} />
                <Chip
                    label={completed === total ? 'Complete' : `${completed}/${total} Completed`}
                    color={completed === 0 ? 'error' : completed === total ? 'success' : 'info'}
                    icon={completed === 0 ? <WarningIcon /> : completed === total ? <CheckIcon /> : <InfoIcon />}
                    size="small"
                />
            </ListItemButton>
        </ListItem>
    );
};
