import type { MetadataItemInstance, MetadataLiteralTypes } from '@nexdynamic/squeegee-common';
import { MetadataCollectionValue } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../../ApplicationState';
import { Data } from '../../../../Data/Data';
import { Logger } from '../../../../Logger';
import { NotifyUserMessage } from '../../../../Notifications/NotifyUserMessage';
import { getMetadataCollectionValue } from '../../getMetadataCollectionValue';

export const skipItemForm = async ({
    values,
    collectionSchemaId,
    itemSchemaKey,
    targetObjectId,
    navigateBack,
}: {
    values: MetadataItemInstance<MetadataLiteralTypes>;
    collectionSchemaId: string;
    itemSchemaKey: number;
    targetObjectId: string;
    navigateBack: () => void;
}) => {
    if (!values) return;

    try {
        let collectionValue = getMetadataCollectionValue({
            targetObjectId,
            valuesSchemaId: collectionSchemaId,
        });

        const skippedItem = values;
        skippedItem.skipped = true;
        skippedItem.value = ApplicationState.localise('general.skipped');

        if (!collectionValue) {
            collectionValue = new MetadataCollectionValue(targetObjectId, collectionSchemaId);
            MetadataCollectionValue.addSkippedMetadataItem({
                metadataCollectionValue: collectionValue,
                indexKey: itemSchemaKey,
                ...skippedItem,
            });
        } else {
            MetadataCollectionValue.addSkippedMetadataItem({
                metadataCollectionValue: collectionValue,
                indexKey: itemSchemaKey,
                ...values,
            });
        }

        if (!collectionValue) return;

        await Data.put(collectionValue);
    } catch (error) {
        new NotifyUserMessage('custom-fields.skipping-form-values-failed');
        Logger.error('Error skipping item form', error);
    } finally {
        navigateBack();
    }
};
