import { Fade, List, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import type { AvailableMetadataTarget } from '@nexdynamic/squeegee-common';
import { availableMetadataTargetTypes, translationKeysForMetadataTargets } from '@nexdynamic/squeegee-common';

import { t } from '../../../t';
import { useGlobalCustomFieldsRouter } from './GlobalCustomFieldsDialog';
import { useGlobalCustomFieldsContext } from './useGlobalCustomFieldsContext';

export const CustomFieldResourceTypeList = () => {
    const { setType } = useGlobalCustomFieldsContext();
    const { navigateTo } = useGlobalCustomFieldsRouter();
    if (!navigateTo || !setType) return null;

    const handleSelectType = (type: AvailableMetadataTarget) => {
        setType(type);
        navigateTo('global-metadata-for-resource-type');
    };

    return (
        <Fade in>
            <Paper sx={{ p: 2 }}>
                <Typography variant="h5">{t('custom-fields.select-resource-type-for-global')}</Typography>

                <List>
                    {availableMetadataTargetTypes
                        .filter(x => x !== 'any')
                        .sort((a, b) => a.localeCompare(b))
                        .map((type: AvailableMetadataTarget) => {
                            const nameAndDescriptionKeys = translationKeysForMetadataTargets[type];
                            const name = t(nameAndDescriptionKeys[0]);
                            const description = t(nameAndDescriptionKeys[1]);

                            return (
                                <ListItemButton key={type} onClick={() => handleSelectType(type)}>
                                    <ListItemText primary={name} secondary={description} />
                                </ListItemButton>
                            );
                        })}
                </List>
            </Paper>
        </Fade>
    );
};
