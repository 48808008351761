import type { MetadataItemInstance, MetadataLiteralTypes } from '@nexdynamic/squeegee-common';
import { useMetadataCompleteContext } from '../../context/useMetadataContext';
import type { SaveFormValues } from '../saveItemFormValues';
import { saveItemFormValues } from '../saveItemFormValues';
import { skipItemForm } from '../skipItemForm';
import { getFormForMetadata } from './getFormForMetadata';

export const ItemSchemaForm = () => {
    const {
        selectedItemSchema: itemSchema,
        targetObject,
        selectedCollectionSchema: collectionSchema,
        navigateBack: navBack,
        itemSchemaKey,
    } = useMetadataCompleteContext();
    const { selectedCollectionValue, setSelectedCollectionValue } = useMetadataCompleteContext();

    if (!collectionSchema || !itemSchema || !targetObject || itemSchemaKey === null) return null;

    let itemInstance: MetadataItemInstance<MetadataLiteralTypes> | undefined = undefined;
    if (selectedCollectionValue) {
        itemInstance = selectedCollectionValue.values[itemSchemaKey];
    }

    const form = getFormForMetadata({
        itemSchema: itemSchema,
        saveValues: async ({ values, formSpecificDatum }: SaveFormValues) => {
            await saveItemFormValues({
                formSpecificDatum,
                targetObjectId: targetObject._id,
                collectionSchemaId: collectionSchema._id,
                values,
                itemSchemaKey,
                setSelectedCollectionValue,
            });
        },
        handleBackClicked: navBack,
        itemInstance: itemInstance,
        skipItem: async (values: MetadataItemInstance<MetadataLiteralTypes>) => {
            await skipItemForm({
                collectionSchemaId: collectionSchema._id,
                itemSchemaKey,
                targetObjectId: targetObject._id,
                values,
                navigateBack: navBack,
            });
        },
    });

    return form;
};
