import type { AvailableMetadataTarget, Customer, Job, MetadataCollectionSchema, StoredObject } from '@nexdynamic/squeegee-common';
import { Data } from '../../../Data/Data';
import { MetadataCollectionService } from '../MetadataCollectionService';
import { getResourceTypeSchemas } from '../getResourceTypeSchemas';
import { resolveSchemaParentId } from '../resolveSchemaParentId';

export const resolveSchemasToComplete = (options: {
    targetId: string;
    type: AvailableMetadataTarget;
    customerId?: string;
}): Array<MetadataCollectionSchema> => {
    const { targetId, type, customerId } = options;
    let target: StoredObject | Job | undefined = Data.get<StoredObject>(targetId);
    let parentId: string | undefined = '';
    if (!target) {
        if (customerId) {
            const customer = Data.get<Customer>(customerId);
            target = customer?.jobs[targetId] || customer?.jobs[targetId.slice(0, -10)];
            parentId = target?._id;
        }

        if (!target) {
            return [];
        }
    }

    if (!parentId) {
        parentId = resolveSchemaParentId(target);
    }

    const schemasAttached = MetadataCollectionService.fetchAllSchema(parentId);

    let globalSchemas: Array<MetadataCollectionSchema> = [];
    globalSchemas = getResourceTypeSchemas({ type });

    return Array.from(new Set([...globalSchemas, ...schemasAttached]));
};
