import type {
    AvailableMetadataTarget,
    Job,
    MetadataCollectionSchema,
    MetadataCollectionValue,
    StoredObject,
    StoredObjectResourceTypes,
    Transaction,
} from '@nexdynamic/squeegee-common';
import { Linker, getMetadataCollectionValuesId } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';
import { JobOccurrenceService } from '../../Jobs/JobOccurrenceService';
import { JobService } from '../../Jobs/JobService';
import { Logger } from '../../Logger';

export class MetadataCollectionService {
    /**
     * Fetch all schemas for the passed parentId
     * @static
     * @param {string} parentId The id of the parent object that the schemas are for ie: customerId, JobId
     * @return {*}  {Array<MetadataCollectionSchema>}
     */
    public static fetchAllSchema(parentId: string): Array<MetadataCollectionSchema> {
        const linker = MetadataCollectionService.getLinkerForParent(parentId);
        const schemas = [];
        if (linker) {
            const links = Linker.getLinks(linker);

            for (const link of links) {
                const schema = Data.get<MetadataCollectionSchema>(link.targetId);
                if (schema) schemas.push(schema);
            }
        }

        return schemas;
    }

    /**
     * Fetch all collections for the passed parentId
     * @static
     * @param {string} parentId The id of the parent object that the collections are for ie: customerId, JobId
     * @returns {Array<MetadataCollectionValue>}
     */
    public static fetchAllCollections(parentId: string): Array<MetadataCollectionValue> {
        const linker = MetadataCollectionService.getLinkerForParent(parentId);
        const collections = [];
        if (linker) {
            const links = Linker.getLinks(linker);

            for (const link of links) {
                const collection = Data.get<MetadataCollectionValue>(link.targetId);
                if (collection) collections.push(collection);
            }
        }

        return collections;
    }

    /**
     * Check if custom field values exist for the passed parent id
     * @static
     * @param {string} parentId
     * @returns boolean
     */
    public static hasCustomFieldValues(parentId: string): boolean {
        const linker = MetadataCollectionService.getLinkerForParent(parentId);

        if (!linker) return false;

        const links = Linker.getLinks(linker);
        return links.length > 0;
    }

    public static getAll(filter?: Partial<MetadataCollectionSchema>): Array<MetadataCollectionSchema> {
        return Data.all<MetadataCollectionSchema>('metadatacollectionschema', { ...filter, isTemplate: false }).slice();
    }

    /**
     * Unlinks the passed schema from the passed linker and syncs changes to server
     * @static
     * @param {string} parentId The id of the parent to remove schema from
     * @param {MetadataCollectionSchema} schema The schema to update
     */
    public static async remove(parentId: string, schema: MetadataCollectionSchema) {
        try {
            const linker = Data.get<Linker>(Linker.getId(parentId, 'metadata'));

            if (linker && linker.resourceType === 'linkers') {
                // Unlink this schema from the linker
                Linker.unlink(linker, schema._id);
                // Update linker
                Data.put(linker);
            } else {
                // This should never happen
                throw 'Unexpected: schema removed was called but no linker existed for the passed schema';
            }
        } catch (error) {
            Logger.error('Unable to remove schema', error);
            throw error;
        }
    }
    /**
     * Takes the passed schema and updated the locally stored object and syncs to server
     * @static
     * @param {MetadataCollectionSchema} schema
     */
    public static async update(schema: MetadataCollectionSchema) {
        try {
            await Data.put(schema);
        } catch (error) {
            Logger.error(`Unable to update schema ${schema._id}`, error);
        }
    }

    /**
     * Gets a linker for a given parent id requires a parent object
     * @static
     * @param {string} parentId
     * @return {*}
     */
    public static getLinkerForParent(parentId: string) {
        return Data.get<Linker>(Linker.getId(parentId, 'metadata'));
    }

    public static getLinkersForMetadataCollectionSchema(schemaId: string) {
        return Data.all<Linker>('linkers', linker => Linker.hasLink(linker, schemaId)).slice();
    }

    /**
     * Determines wether the passed schema id is linked to any other resources
     * @static
     * @param {string} schemaId
     * @return {*}  {boolean}
     */
    public static otherLinks(schemaId: string): boolean {
        // Get all linkers and find ones with this schema id
        const query = (linker: Linker) => Linker.hasLink(linker, schemaId);

        return Data.all('linkers', query).length > 0;
    }

    /**
     * Deletes schema and unlinks from all linkers which once synced to server will remove the remote file stored in cloud storage
     * @static
     * @param {MetadataCollectionSchema} schema
     */
    public static async delete(schema: MetadataCollectionSchema) {
        try {
            // get all linkers for this schema

            const linkers = MetadataCollectionService.getLinkersForMetadataCollectionSchema(schema._id);
            const linkersToUpdate: Array<Linker> = [];
            for (const linker of linkers) {
                Linker.unlink(linker, schema._id);
                linkersToUpdate.push(linker);
            }

            await Data.put(linkersToUpdate);
            await Data.delete(schema);
        } catch (error) {
            Logger.error(`Unable to delete schema ${schema ? schema._id : 'Unknown schema id'}`, error);
            throw error;
        }
    }

    /**
     * Returns all items that the passed schemaId is linked to
     * @static
     * @param {string} schemaId
     * @return {*}  {Array<StoredObject>}
     * @memberof MetadataService
     */
    public static getMetadataCollectionSchemaParents(schemaId: string): Array<StoredObject> {
        const parents = [];

        const linkers = MetadataCollectionService.getLinkersForMetadataCollectionSchema(schemaId);

        for (const linker of linkers) {
            const parentId = Linker.getParentId(linker);
            let parent = Data.get(parentId);

            // If parent does not exists then check for transient parents
            if (!parent) {
                switch (linker.parentType) {
                    case 'joboccurrences':
                        try {
                            parent = JobOccurrenceService.getOrCreateJobOccurence(parentId);
                        } catch (error) {
                            Logger.error('Unable to get parent for joboccurrence linker');
                        }
                        break;
                    // WTF Jobs exist on customers so we need to fetch them
                    case 'jobs':
                        try {
                            parent = JobService.getJob(undefined, parentId);
                        } catch (error) {
                            Logger.error('Unable to get parent for job linker');
                        }
                        break;
                }
            }

            if (parent) parents.push(parent);
            else Logger.error(`Error fetching schema parent: ${parentId} does not exist! this should not happen`);
        }

        return parents;
    }
    /**
     * Links the passed schema to the parent
     * @static
     * @param {StoredObject | Job} parent
     * @param {MetadataCollectionSchema} schema
     */
    public static async linkMetadataCollectionSchema(schema: MetadataCollectionSchema, parent: StoredObject | Job) {
        let linker = MetadataCollectionService.getLinkerForParent(parent._id);
        // If there isn't a linker for this parent then create a new one
        if (!linker)
            linker = new Linker(
                parent._id,
                MetadataCollectionService.getParentType(parent),
                MetadataCollectionService.getParentSubType(parent),
                'metadata'
            );

        Linker.link(linker, schema);

        await Data.put(linker);
    }

    /**
     * Returns a parent subtype for the passed parent
     * @static
     * @param {StoredObject} parent
     * @return {*}
     */
    public static getParentSubType(parent: StoredObject) {
        if ((parent as Transaction).transactionType) return (parent as Transaction).transactionType;

        return '';
    }

    /**
     * Gets a resourceType for the passed parent used to fix issues with some stored objects
     * @static
     * @param {StoredObject} parent
     * @return {*}
     */
    public static getParentType(parent: StoredObject): StoredObjectResourceTypes {
        if (parent.resourceType) return parent.resourceType;
        else {
            // WTF Jobs don't have resource types but they can be attached to so we need a resourceType remove when jobs are out of customers
            return 'jobs';
        }
    }

    /**
     * Checks if all required fields are filled in for the passed object
     * @static
     * @param targetId the ID of the object that needs to be checked for required fields being filled in.
     * @returns {boolean} true if all required fields are filled in, false otherwise.
     */
    public static async allRequiredFieldsAreCompletedForTarget(targetId: string, type: AvailableMetadataTarget): Promise<boolean> {
        const realTargetId = type === 'job-occurrence-completion' ? targetId.slice(0, -10) : targetId;

        const schemas = MetadataCollectionService.fetchAllSchema(realTargetId);

        const requiredKeys = new Map<string, number>();
        for (const schema of schemas) {
            for (const [key, item] of Object.entries(schema.items)) {
                if (!item.skippable) {
                    requiredKeys.set(schema._id, Number(key));
                }
            }
        }

        if (!requiredKeys.size) return true;

        let allRequiredFieldsAreCompleted = true;
        for (const [schemaId, key] of requiredKeys) {
            const collectionValueId = getMetadataCollectionValuesId({
                targetObjectId: type === 'job-occurrence-completion' ? targetId : realTargetId,
                valuesSchemaId: schemaId,
            });

            const collectionValue = Data.get<MetadataCollectionValue>(collectionValueId);
            if (!collectionValue) {
                allRequiredFieldsAreCompleted = false;
                break;
            }

            if (collectionValue.values[key]?.value === undefined) {
                allRequiredFieldsAreCompleted = false;
                break;
            }
        }

        return allRequiredFieldsAreCompleted;
    }

    public static allRequiredFieldsForSchemaAreCompleted(
        schema: MetadataCollectionSchema,
        collectionValue: MetadataCollectionValue
    ): boolean {
        for (const [key, item] of Object.entries(schema.items)) {
            const itemIsRequired = !item.skippable;
            const valueForItem = collectionValue.values[Number(key)]?.value;

            if (itemIsRequired && valueForItem === undefined) {
                return false;
            }
        }

        return true;
    }

    /**
     * Checks if the passed schema has any required fields
     * @static
     * @param {MetadataCollectionSchema} schema
     * @returns {boolean} true if the schema has required fields, false otherwise.
     */
    public static async schemaHasRequiredFields(schema: MetadataCollectionSchema): Promise<boolean> {
        for (const item of Object.values(schema.items)) {
            if (!item.skippable) return true;
        }

        return false;
    }

    /**
     * Checks if the meta data schema is in use anywhere (attached to any objects)
     * @static
     * @param {string} schemaId
     * @returns {boolean} true if the schema is in use, false otherwise.
     */
    public static isSchemaInUse(schemaId: string): boolean {
        return Boolean(Data.all<MetadataCollectionValue>('metadatacollection', { schemaId }));
    }

    /**
     * Checks if a specific field within a schema is being used in any collection values
     * @static
     * @param {string} schemaId - The ID of the schema to check
     * @param {number} fieldIndex - The index of the field within the schema to check
     * @returns {boolean} - True if the field is in use in any collection values, false otherwise
     */
    public static isSchemaFieldInUse(schemaId: string, fieldIndex: number): boolean {
        return Data.all<MetadataCollectionValue>('metadatacollection', { schemaId }).some(c => !!c.values[fieldIndex]);
    }

    /**
     * Deletes the schema item from the schema
     * WARNING: Must only be called if this schema is not in use anywhere!
     * @static
     * @param {string} schemaId
     * @param {number} itemkeyIndex
     * @memberof MetadataCollectionService
     * @returns {Promise<void>}
     */
    public static async deleteSchemaItem(schemaId: string, itemkeyIndex: number) {
        const schema = Data.get<MetadataCollectionSchema>(schemaId);
        if (!schema) return;

        if (!schema.items[itemkeyIndex]) return Logger.error(`Item ${itemkeyIndex} does not exist in schema ${schemaId}`);
        delete schema.items[itemkeyIndex];

        await Data.put(schema);
    }

    public static getTemplates(): Array<MetadataCollectionSchema> {
        return Data.all<MetadataCollectionSchema>('metadatacollectionschema', { isTemplate: true }).slice();
    }
}
