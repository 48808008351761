import { Box, Chip, Stack, TextField } from '@mui/material';
import { t } from '../../../t';
import type { CustomFieldsDialogFiltersType } from './CustomFieldDialog';

type CustomFieldsDialogFiltersProps = {
    setFilters: (filters: CustomFieldsDialogFiltersType) => void;
    filters: CustomFieldsDialogFiltersType;
};
export const CustomFieldsDialogFilters = ({ setFilters, filters }: CustomFieldsDialogFiltersProps) => {
    return (
        <Stack direction="row" spacing={1} sx={{ margin: 1 }}>
            <Box sx={{ flex: 1, alignItems: 'center' }}>
                <TextField
                    type="text"
                    placeholder={t('general.search')}
                    value={filters.search}
                    onChange={e => setFilters({ ...filters, search: e.target.value })}
                    sx={{ width: '100%' }}
                    variant="outlined"
                />
            </Box>
            {filters.filterTarget && (
                <Box sx={{ justifyContent: 'end', alignContent: 'center' }}>
                    <Chip
                        size="medium"
                        key={filters.filterTarget}
                        label={t(`custom-fields.target-${filters.filterTarget}`)}
                        color="primary"
                        sx={{ borderRadius: 0 }}
                        variant="outlined"
                    />
                </Box>
            )}
        </Stack>
    );
};
