import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import type { MetadataItemSchema, MetadataLiteralTypes } from '@nexdynamic/squeegee-common';
import { MetadataCollectionSchema, wait } from '@nexdynamic/squeegee-common';
import { enqueueSnackbar } from 'notistack';
import { Data } from '../../../Data/Data';
import { t } from '../../../t';
import { ItemSchemaCloneList } from './ItemSchemaCloneList';

export const ItemSchemaCloneDialog = ({
    collectionSchemaForCloning,
    schemaCloningInto,
    closeDialog,
}: {
    collectionSchemaForCloning: MetadataCollectionSchema | null;
    schemaCloningInto: MetadataCollectionSchema | null;
    closeDialog: () => void;
}) => {
    const theme = useTheme();
    if (!collectionSchemaForCloning || !schemaCloningInto) return null;

    const cloneField = async (itemToClone: MetadataItemSchema<MetadataLiteralTypes>) => {
        const latestSchema = Data.get<MetadataCollectionSchema>(schemaCloningInto._id);
        if (!latestSchema) return;
        MetadataCollectionSchema.addItemSchema({
            schema: latestSchema,
            itemSchema: itemToClone,
        });
        await wait(200);
        closeDialog();
        await Data.put(latestSchema);
        enqueueSnackbar(t('custom-fields.cloned-field-successfully', { field: itemToClone.title }), { variant: 'success' });
    };
    return (
        <Dialog open={!!open} fullWidth maxWidth="sm">
            <DialogTitle
                sx={{
                    backgroundColor: theme.palette.action.disabledBackground,
                }}
            >
                {t('custom-fields.clone-field')}
            </DialogTitle>
            <DialogContent
                sx={{
                    backgroundColor: theme.palette.action.disabledBackground,
                }}
            >
                <ItemSchemaCloneList itemSchemas={collectionSchemaForCloning.items} cloneField={cloneField} />
            </DialogContent>
            <DialogActions
                sx={{
                    backgroundColor: theme.palette.action.disabledBackground,
                }}
            >
                <Button color="primary" variant="contained" onClick={closeDialog}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
