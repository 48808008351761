import type { AvailableMetadataTarget, MetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { notNullUndefinedEmptyOrZero } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';
import { getGlobalSchemaIdsFromType } from './getGlobalSchemaIdsFromType';

export const getResourceTypeSchemas = (options: { type: AvailableMetadataTarget }): Array<MetadataCollectionSchema> => {
    return getGlobalSchemaIdsFromType(options.type)
        .map(schemaId => Data.get<MetadataCollectionSchema>(schemaId))
        .filter(notNullUndefinedEmptyOrZero);
};
