import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import AppProviders from '../../../AppProviders';
import { SqueegeeThemeProvider } from '../../../SqueegeeThemeProvider';
import { BrowserFallBackToHashRouter } from '../../../components/BrowserFallBackToHashRouter';
import { CustomFieldsApp } from './CustomFieldsApp';

export const CustomFields = (element: HTMLElement) => {
    const root = createRoot(element);

    root.render(
        <AppProviders>
            <BrowserFallBackToHashRouter>
                <SqueegeeThemeProvider>
                    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <CustomFieldsApp />
                    </SnackbarProvider>
                </SqueegeeThemeProvider>
            </BrowserFallBackToHashRouter>
        </AppProviders>
    );

    return {
        unmount: () => root.unmount(),
    };
};
