import CloseIcon from '@mui/icons-material/CloseRounded';
import FileIcon from '@mui/icons-material/InsertDriveFileRounded';
import { IconButton, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Paper, Stack, TextField } from '@mui/material';
import { FileUploadArea } from '@nexdynamic/nex-ui-react';
import type { Attachment, MetadataItemInstance } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import { AttachmentService } from '../../../../../../Attachments/AttachmentService';
import { SelectAttachmentsDialog } from '../../../../../../Attachments/Dialogs/SelectAttachmentsDialog';
import { Data } from '../../../../../../Data/Data';
import { t } from '../../../../../../t';
import bytesToMbString from '../../../../../email-settings/utils/bytesToMbString';
import { FieldFormHeader } from '../../../dialog/components/FieldFormHeader';
import type { MetadataFormProps } from '../getFormForMetadata';

export const AttachmentMetadataItemForm: React.FC<MetadataFormProps<'attachment'>> = ({
    itemSchema,
    saveValues,
    itemInstance,
    skipItem,
}) => {
    const [values, setValues] = useState<MetadataItemInstance<'attachment'>>(itemInstance ?? {});
    const [attachment, setAttachment] = useState<Attachment | undefined>(
        itemInstance?.value ? Data.get<Attachment>(itemInstance.value) : undefined
    );

    const isImage = attachment?.mimeType?.startsWith('image/');

    const getAttachment = async () => {
        const attachmentDialog = new SelectAttachmentsDialog([], { isPublic: true, selectOne: true });
        const selectedAttachments = await attachmentDialog.show();
        const selectedAttachment = selectedAttachments[0];

        if (attachmentDialog.cancelled || !selectedAttachment) return;

        setAttachment(selectedAttachment);
        setValues({ ...values, value: selectedAttachment._id });
    };

    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues(prev => ({
            ...prev,
            notes: event.target.value,
        }));
    };

    const handleRemoveAttachment = () => {
        setAttachment(undefined);
        setValues({ ...values, value: undefined });
    };

    return (
        <Stack spacing={2}>
            <FieldFormHeader
                itemSchema={itemSchema}
                itemInstance={itemInstance}
                values={values}
                setValues={setValues}
                skipItem={skipItem}
                saveValues={saveValues}
            />

            <Stack spacing={3}>
                <FileUploadArea
                    title={attachment ? t('custom-fields.replace-attachment') : t('attachments.upload-title')}
                    subtitle={t('attachments.upload-description')}
                    onClick={getAttachment}
                    sx={{ width: '100%' }}
                    showFiles
                    disableFileInput
                />
                {attachment && (
                    <ListItem
                        component={Paper}
                        secondaryAction={
                            <IconButton onClick={handleRemoveAttachment}>
                                <CloseIcon />
                            </IconButton>
                        }
                        sx={{ minHeight: 64 }}
                    >
                        {isImage ? (
                            <ListItemAvatar>
                                <Paper
                                    component="img"
                                    src={AttachmentService.getPublicUrl(attachment)}
                                    alt={attachment.name}
                                    height={48}
                                    elevation={0}
                                />
                            </ListItemAvatar>
                        ) : (
                            <ListItemIcon>
                                <FileIcon />
                            </ListItemIcon>
                        )}
                        <ListItemText primary={attachment.name} secondary={bytesToMbString(attachment.size)} sx={{ ml: 2 }} />
                    </ListItem>
                )}

                <TextField
                    label={t('general.notes')}
                    value={values.notes}
                    onChange={handleNotesChange}
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 255 }}
                    sx={{ mt: 1 }}
                />
            </Stack>
        </Stack>
    );
};
