import { Button, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Switch } from '@mui/material';
import { Stack } from '@mui/system';
import { EmptyList } from '@nexdynamic/nex-ui-react';
import { ApplicationState } from '../../../ApplicationState';
import { t } from '../../../t';
import type { SchemaItem } from './CustomFieldDialog';

export const CustomFieldList = ({
    schemas,
    submitSelectedSchemas,
    toggledItems,
    onToggle,
}: {
    schemas: Map<string, SchemaItem>;
    submitSelectedSchemas: (selectedSchemas: Map<string, SchemaItem>) => void;
    toggledItems: Set<string>;
    onToggle: (id: string) => void;
}) => {
    if (schemas.size === 0) {
        return <EmptyList text={t('custom-fields.no-results-on-filters')} />;
    }

    return (
        <div>
            <Stack direction={'row'} justifyContent={'right'}>
                <Button variant="contained" onClick={() => submitSelectedSchemas(schemas)}>
                    {ApplicationState.localise('general.save')}
                </Button>
            </Stack>
            <List>
                {Array.from(schemas.values()).map(({ schema }) => (
                    <Paper key={schema._id} sx={{ margin: 1 }}>
                        <ListItem>
                            <ListItemText primary={schema.title} />
                            <ListItemSecondaryAction>
                                <Switch checked={toggledItems.has(schema._id)} onChange={() => onToggle(schema._id)} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Paper>
                ))}
            </List>
        </div>
    );
};
