import CheckIcon from '@mui/icons-material/CheckRounded';
import CancelIcon from '@mui/icons-material/CloseRounded';
import SkipIcon from '@mui/icons-material/RotateRightRounded';
import { Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import type { BooleanMetadataTypes, MetadataItemSchema } from '@nexdynamic/squeegee-common';
import useTranslation from '../../../../../../hooks/useTranslation';

type Props = {
    itemSchema: MetadataItemSchema<BooleanMetadataTypes>;
    value: 'skipped' | true | false | undefined;
    onSkipClick?: () => void;
    onTrueClick: () => void;
    onFalseClick: () => void;
    enableButtonLabels?: boolean;
    size?: 'small' | 'medium' | 'large';
};

export const DoneNotDoneSkippedToggle: React.FC<Props> = ({
    itemSchema,
    value,
    onSkipClick,
    onTrueClick,
    onFalseClick,
    enableButtonLabels,
    size = 'medium',
}) => {
    const { t } = useTranslation();
    const stopPropagation = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation();

    return (
        <ToggleButtonGroup value={value} onClick={stopPropagation} size={size}>
            <Tooltip title={!enableButtonLabels && t('general.no')} arrow>
                <ToggleButton
                    onClick={onFalseClick}
                    onMouseDown={stopPropagation}
                    onMouseUp={stopPropagation}
                    value={false}
                    color="error"
                    sx={{
                        '&.Mui-selected': {
                            'backgroundColor': 'error.main',
                            'color': 'error.contrastText',
                            '&:hover': {
                                backgroundColor: 'error.dark',
                            },
                        },
                    }}
                >
                    <Stack direction="row" gap={1}>
                        <CancelIcon />
                        {enableButtonLabels && (
                            <Typography variant="button" mr={0.5}>
                                {t('general.no')}
                            </Typography>
                        )}
                    </Stack>
                </ToggleButton>
            </Tooltip>

            <Tooltip title={!enableButtonLabels && t('general.yes')} arrow>
                <ToggleButton
                    onClick={onTrueClick}
                    onMouseDown={stopPropagation}
                    onMouseUp={stopPropagation}
                    value={true}
                    color="success"
                    sx={{
                        '&.Mui-selected': {
                            'backgroundColor': 'success.main',
                            'color': 'success.contrastText',
                            '&:hover': {
                                backgroundColor: 'success.dark',
                            },
                        },
                    }}
                >
                    <Stack direction="row" gap={1}>
                        <CheckIcon />
                        {enableButtonLabels && (
                            <Typography variant="button" mr={0.5}>
                                {t('general.yes')}
                            </Typography>
                        )}
                    </Stack>
                </ToggleButton>
            </Tooltip>

            {itemSchema.skippable && !!onSkipClick && (
                <Tooltip title={!enableButtonLabels && t('general.skip')} arrow>
                    <ToggleButton
                        onClick={onSkipClick}
                        onMouseDown={stopPropagation}
                        onMouseUp={stopPropagation}
                        value="skipped"
                        color="warning"
                        sx={{
                            '&.Mui-selected': {
                                'backgroundColor': 'warning.main',
                                'color': 'warning.contrastText',
                                '&:hover': {
                                    backgroundColor: 'warning.dark',
                                },
                            },
                        }}
                    >
                        <Stack direction="row" gap={1}>
                            <SkipIcon />
                            {enableButtonLabels && (
                                <Typography variant="button" mr={0.5}>
                                    {t('general.skipped')}
                                </Typography>
                            )}
                        </Stack>
                    </ToggleButton>
                </Tooltip>
            )}
        </ToggleButtonGroup>
    );
};
