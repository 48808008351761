import RuleIcon from '@mui/icons-material/Rule';
import { Box, List, Stack } from '@mui/material';
import { EmptyList, SearchQueryField, useSearchQueryParams } from '@nexdynamic/nex-ui-react';
import type { MetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import { t } from '../../../t';
import useStoredObjects from '../../hooks/useStoredObjects';
import { CollectionSchemaItem } from './MetadataCollectionItem';

export const MetadataCollectionList = ({ view }: { view: 'schema' | 'template' }) => {
    const {
        searchQuery: [search],
    } = useSearchQueryParams();

    const where = useMemo(() => {
        if (search) {
            return (schema: MetadataCollectionSchema) => searchFilter(search, schema, view);
        } else {
            if (view === 'schema') {
                return (schema: MetadataCollectionSchema) => !schema.isTemplate;
            } else {
                return (schema: MetadataCollectionSchema) => schema.isTemplate;
            }
        }
    }, [search, view]);

    const schemas = useStoredObjects<MetadataCollectionSchema>('metadatacollectionschema', undefined, where);
    console.log('schemas', schemas);
    const schemaItems = useMemo(() => {
        if (schemas.length === 0) {
            return (
                <EmptyList
                    text={search?.length ? t('custom-fields.schemas-search-no-results') : t('custom-fields.schemas-empty')}
                    icon={<RuleIcon />}
                />
            );
        }

        return schemas.length ? (
            schemas.map(schema => {
                return <CollectionSchemaItem schema={schema} key={schema._id} />;
            })
        ) : (
            <EmptyList text={t('custom-fields.schemas-empty')} icon={<RuleIcon />} />
        );
    }, [schemas, search, view]);

    return (
        <Box py={1}>
            <SearchQueryField placeholder={t('general.search')} />
            <List component={Stack} spacing={1}>
                {schemaItems}
            </List>
        </Box>
    );
};
const searchFilter = (search: string, schema: MetadataCollectionSchema, view: 'schema' | 'template') => {
    if (view === 'schema') {
        const searchText = search.toLowerCase();
        const titleMatch = schema.title.toLowerCase().includes(searchText);
        const targetMatch = schema.target && searchText.includes(schema.target.toLowerCase());
        return !schema.isTemplate && (titleMatch || Boolean(targetMatch));
    } else if (view === 'template') {
        const searchText = search.toLowerCase();
        const titleMatch = schema.title.toLowerCase().includes(searchText);
        const targetMatch = schema.target && searchText.includes(schema.target.toLowerCase());
        return schema.isTemplate && (titleMatch || Boolean(targetMatch));
    }

    return false;
};
