import type { AvailableMetadataTarget } from '@nexdynamic/squeegee-common';
import { Data } from '../../../../Data/Data';
import { AureliaReactComponentDialog } from '../../../../Dialogs/AureliaReactComponentDialog';
import { NotifyUserMessage } from '../../../../Notifications/NotifyUserMessage';
import { CustomFieldDialog } from '../CustomFieldDialog';

export type CustomFieldsDialogProps = {
    relatedObjectId: string;
    filterTarget: AvailableMetadataTarget;
    close: () => Promise<void>;
    customerId?: string; // for jobs
    type: AvailableMetadataTarget;
};
export const addCustomFields = async (props: CustomFieldsDialogProps) => {
    const closeDialog = async () => {
        await dialog.ok();
    };

    if (!Data.exists('metadatacollectionschema')) {
        new NotifyUserMessage('custom-fields.create-some-schemas-first');
        return;
    }

    const dialog = new AureliaReactComponentDialog<void, CustomFieldsDialogProps>({
        component: CustomFieldDialog,
        componentProps: { ...props, close: closeDialog },
        dialogTitle: 'jobs.add-custom-fields',
        isSecondaryView: true,
    });

    await dialog.show();

    if (dialog.cancelled) return;
};
