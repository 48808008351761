import type { JobOccurrence, StoredObject } from '@nexdynamic/squeegee-common';

export const resolveSchemaParentId = (targetObject: StoredObject): string => {
    switch (targetObject.resourceType) {
        case 'joboccurrences': {
            return (targetObject as JobOccurrence).jobId || (targetObject as JobOccurrence)._id.slice(0, -10);
        }
        default: {
            const object = targetObject as StoredObject;
            return object._id;
        }
    }
};
