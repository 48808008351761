import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ViewLoading from '../../../components/ViewLoading';
import { MetadataCollectionSchemaView } from '../../CreateMetadata/MetadataCollectionSchemaView';
import { CustomFieldsMain } from './CustomFieldsMain';

export const CustomFieldsApp = () => {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Routes>
                <Route path="custom-fields/main" element={<CustomFieldsMain />}></Route>
                <Route path="custom-fields/schemas/:schemaId" element={<MetadataCollectionSchemaView />} />
                <Route path="custom-fields/schemas/items/edit/:schemaId/:itemIndex" element={<MetadataCollectionSchemaView />}></Route>
            </Routes>
        </Suspense>
    );
};
