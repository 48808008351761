import { Fade } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import type { AvailableMetadataTarget } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../ApplicationState';
import type { CustomFieldDataStructure, CustomFieldsDialogFiltersType } from '../AddCustomFieldsDialog/CustomFieldDialog';
import { CustomFieldsDialogFilters } from '../AddCustomFieldsDialog/CustomFieldsDialogFiltersComponent';
import { buildGlobalSchemaStructure } from '../AddCustomFieldsDialog/HelperFunctions/buildCustomFieldSchemaStructure';
import { PaginatedCustomFieldList } from '../AddCustomFieldsDialog/PaginatedCustomFieldList';
import { useGlobalCustomFieldsContext } from './useGlobalCustomFieldsContext';

export const CustomFieldResourceType = () => {
    const { type, closeDialog } = useGlobalCustomFieldsContext();
    const globalSchemaDataStructure = useMemo(() => buildGlobalSchemaStructure(type), [type]);

    const [filters, setFilters] = useState<CustomFieldsDialogFiltersType>({
        search: '',
        filterTarget: type,
    });
    const [filteredSchemas, setFilteredSchemas] = useState<CustomFieldDataStructure | null>(globalSchemaDataStructure);

    const tItems = Array.from(globalSchemaDataStructure.values())
        .filter(schemaAndIfEnabled => schemaAndIfEnabled.isEnabled)
        .map(schemaAndIfEnabled => schemaAndIfEnabled.schema._id);
    const [toggledItems, setToggledItems] = useState<Set<string>>(new Set(tItems));

    const handleToggle = (id: string) => {
        setToggledItems(prev => {
            const newToggledItems = new Set(prev);
            if (newToggledItems.has(id)) {
                newToggledItems.delete(id);
            } else {
                newToggledItems.add(id);
            }
            return newToggledItems;
        });
    };

    useEffect(() => {
        if (!type) return;
        if (!globalSchemaDataStructure) return;

        const search = filters.search.trim().toLowerCase();
        if (!filters.filterTarget) setFilteredSchemas(globalSchemaDataStructure);
        if (search === '') setFilteredSchemas(globalSchemaDataStructure);

        const filtered = new Map(
            [...globalSchemaDataStructure].filter(([, value]) => {
                const targetMatch = value.schema.target === filters.filterTarget || value.schema.target === 'any';
                const searchMatch =
                    value.schema.title.trim().toLowerCase().includes(search) ||
                    value.schema.description?.trim().toLowerCase().includes(search);

                return targetMatch && searchMatch;
            })
        );

        setFilteredSchemas(filtered);
    }, [filters.search, type]);
    if (!type) return null;

    const handleSubmit = async () => {
        const schemaIds = Array.from(toggledItems);

        await ApplicationState.setSetting<Array<string>, AvailableMetadataTarget>('global.metadata-resource-type', schemaIds, type);

        closeDialog && closeDialog();
    };

    if (!filteredSchemas) return null;

    return (
        <Fade in>
            <div>
                <CustomFieldsDialogFilters filters={filters} setFilters={setFilters} />
                <PaginatedCustomFieldList
                    pageSize={10}
                    schemas={filteredSchemas}
                    submitSelectedSchemas={handleSubmit}
                    handleToggle={handleToggle}
                    toggledItems={toggledItems}
                />
            </div>
        </Fade>
    );
};
