import { Box, MenuItem } from '@mui/material';
import { availableMetadataTargetTypes } from '@nexdynamic/squeegee-common';
import { Field } from 'formik';
import { Select, TextField } from 'formik-mui';
import { t } from '../../../../t';

export const MetadataCollectionForm = ({ editing }: { editing?: boolean }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
            <Field
                component={Select}
                name="target"
                sx={{ width: '100%' }}
                disabled={!!editing}
                label={t('custom-fields.attach-collection-to-target')}
            >
                {availableMetadataTargetTypes.map(target => (
                    <MenuItem key={target} value={target}>
                        {t(`custom-fields.target-${target}`)}
                    </MenuItem>
                ))}
            </Field>

            <Field component={TextField} name="title" sx={{ width: '100%' }} label={t('custom-fields.collection-title-label')} />

            <Field
                component={TextField}
                name="description"
                sx={{ width: '100%' }}
                label={t('custom-fields.collection-description-label')}
                multiline
                rows={2}
            />
        </Box>
    );
};
