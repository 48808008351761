import ContinueIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import SkipIcon from '@mui/icons-material/RotateRightRounded';
import UnskipIcon from '@mui/icons-material/UndoRounded';
import { Button, Stack, Typography } from '@mui/material';
import { SmartHeader, useViewRouter } from '@nexdynamic/nex-ui-react';
import type { StoredObject, TranslationKey } from '@nexdynamic/squeegee-common';
import {
    NotesRequirement,
    type MetadataItemInstance,
    type MetadataItemSchema,
    type MetadataLiteralTypes,
} from '@nexdynamic/squeegee-common';
import { useSnackbar } from 'notistack';
import { prompt } from '../../../../../Dialogs/ReactDialogProvider';
import useTranslation from '../../../../hooks/useTranslation';
import type { SaveFormValues } from '../../forms/saveItemFormValues';

type Props = {
    itemSchema: MetadataItemSchema<MetadataLiteralTypes>;
    itemInstance: MetadataItemInstance<MetadataLiteralTypes> | undefined;
    formSpecificDatum?: StoredObject;
    values: MetadataItemInstance<MetadataLiteralTypes>;
    setValues: (values: MetadataItemInstance<MetadataLiteralTypes>) => void;
    skipItem: (item: MetadataItemInstance<MetadataLiteralTypes>) => Promise<void>;
    saveValues: (values: SaveFormValues) => void;
};

export const FieldFormHeader: React.FC<Props> = ({
    itemSchema,
    itemInstance,
    formSpecificDatum,
    values,
    setValues,
    skipItem,
    saveValues,
}) => {
    const { t } = useTranslation();
    const { navigateBack } = useViewRouter();
    const { enqueueSnackbar } = useSnackbar();

    const handleSkip = async () => {
        const skipReasonRequired =
            itemSchema.notesRequired === NotesRequirement.WhenSkipped || itemSchema.notesRequired === NotesRequirement.WhenSkippedOrFalsy;

        if (skipReasonRequired && !values.notes) {
            enqueueSnackbar(t('custom-fields.skip-reason-required'), { variant: 'error' });
            return;
        }

        const newValues = {
            ...values,
            skipped: true,
        };

        skipItem(newValues);
    };

    const handleUnskip = async () => {
        const newValues = {
            ...values,
            skipped: false,
        };

        setValues(newValues);
        saveValues({ values, formSpecificDatum });
    };

    const handleBack = async () => {
        const changes = Object.keys(values).some((key: keyof MetadataItemInstance<'text'>) => values[key] !== itemInstance?.[key]);

        if (!changes) {
            navigateBack();
        } else {
            const ok = await prompt(
                'alerts.unsaved-changes',
                'You have unsaved changes. Are you sure you want to continue?' as TranslationKey,
                {
                    okLabel: 'general.continue',
                }
            ).show();

            if (ok) navigateBack();
        }
    };

    const handleContinue = () => {
        if (!values.notes && itemSchema.notesRequired === NotesRequirement.Always) {
            enqueueSnackbar(t('custom-fields.notes-required'), { variant: 'warning' });
            return;
        }

        saveValues({ values, formSpecificDatum });
        navigateBack();
    };

    return (
        <>
            <SmartHeader
                title={itemSchema.title}
                onBack={handleBack}
                actions={
                    <Stack direction="row" spacing={2}>
                        {itemSchema.skippable && !values.skipped && (
                            <Button onClick={handleSkip} startIcon={<SkipIcon />}>
                                {t('general.skip')}
                            </Button>
                        )}

                        {values.skipped ? (
                            <Button onClick={handleUnskip} startIcon={<UnskipIcon />}>
                                {t('general.unskip')}
                            </Button>
                        ) : (
                            <Button onClick={handleContinue} startIcon={<ContinueIcon />} variant="contained">
                                {t('general.continue')}
                            </Button>
                        )}
                    </Stack>
                }
            />

            {itemSchema.description && <Typography>{itemSchema.description}</Typography>}
        </>
    );
};
