import { DialogRoot, ViewRenderer, ViewRouter, createViewRouter, useViewRouter } from '@nexdynamic/nex-ui-react';
import { useMemo } from 'react';
import type { AureliaReactComponentDialogComponentStandardProps } from '../../../Dialogs/AureliaReactComponentDialogComponent';
import AppProviders from '../../AppProviders';
import ApplicationStateProvider from '../../components/ApplicationStateProvider';
import { BrowserFallBackToHashRouter } from '../../components/BrowserFallBackToHashRouter';
import { CustomFieldResourceType } from './CustomFieldResourceType';
import { CustomFieldResourceTypeList } from './CustomFieldResourceTypeList';
import { GlobalCustomFieldsContextProvider } from './useGlobalCustomFieldsContext';

type Views = 'resource-type-list' | 'global-metadata-for-resource-type';
export const useGlobalCustomFieldsRouter = () => useViewRouter<Views>();

type GlobalCustomFieldsDialogProps = AureliaReactComponentDialogComponentStandardProps<any>;
export const GlobalCustomFieldsDialog = ({ cancelCallback }: GlobalCustomFieldsDialogProps) => {
    const metadataCompleteViews = {
        'resource-type-list': () => <CustomFieldResourceTypeList />,
        'global-metadata-for-resource-type': () => <CustomFieldResourceType />,
    } as const;
    const viewRoutes = useMemo(() => {
        return createViewRouter<Views>(metadataCompleteViews);
    }, []);

    return (
        <ApplicationStateProvider>
            <BrowserFallBackToHashRouter>
                <AppProviders>
                    <ViewRouter initialView="resource-type-list">
                        <GlobalCustomFieldsContextProvider close={cancelCallback}>
                            <DialogRoot>
                                <ViewRenderer views={viewRoutes} />
                            </DialogRoot>
                        </GlobalCustomFieldsContextProvider>
                    </ViewRouter>
                </AppProviders>
            </BrowserFallBackToHashRouter>
        </ApplicationStateProvider>
    );
};
