import { Paper } from '@mui/material';
import type { Attachment } from '@nexdynamic/squeegee-common';
import { AttachmentService } from '../../../../../../Attachments/AttachmentService';
import { Data } from '../../../../../../Data/Data';
import { CustomFieldListItem } from '../../components/CustomFieldListItem';
import type { CompletedFieldProps } from './CompletedFieldProps';
import { useMemo } from 'react';

export const CompletedAttachmentField = ({ itemSchema, item, itemSchemaKey }: CompletedFieldProps<'attachment'>) => {
    const attachment = useMemo(() => (item.value ? Data.get<Attachment>(item.value) : undefined), [item.value]);
    const isImage = attachment?.mimeType.startsWith('image/');

    return (
        <CustomFieldListItem
            item={item}
            itemSchema={itemSchema}
            itemSchemaKey={itemSchemaKey}
            secondaryOverride={attachment?.name}
            slots={{
                contentAfter: !item.skipped && attachment && isImage && (
                    <Paper
                        component="img"
                        src={AttachmentService.getPublicUrl(attachment)}
                        alt={attachment.name}
                        height={48}
                        elevation={0}
                        sx={{ mr: 2 }}
                    />
                ),
            }}
        />
    );
};
