import { activationStrategy } from 'aurelia-router';
import { CustomFields } from './ReactView/CustomFields';

export class CustomFieldsModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    customFields: ReturnType<typeof CustomFields>;

    attached() {
        const container = document.getElementById('custom-fields-root');
        if (!container) throw new Error('metadata-root element not found');
        this.customFields = CustomFields(container);
    }

    detached() {
        this.customFields.unmount();
    }
}
