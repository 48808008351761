import { Button, Dialog, DialogActions, Stack, TextField } from '@mui/material';
import type { TranslationKey, ZodMetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { MetadataCollectionSchema, debounce } from '@nexdynamic/squeegee-common';
import { enqueueSnackbar } from 'notistack';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Data } from '../../../Data/Data';
import { t } from '../../../t';
import { SimpleTab } from '../AddCustomFieldsDialog/Tabs/SimpleTab';
import { CollectionTemplateList } from './CollectionTemplateList';

export const DisplayTemplateOptions = ({
    standardTemplates,
    customTemplates,
    closeTemplatesDialog,
}: {
    standardTemplates: Array<ZodMetadataCollectionSchema> | null;
    customTemplates: Array<ZodMetadataCollectionSchema> | null;
    closeTemplatesDialog: Dispatch<SetStateAction<Array<ZodMetadataCollectionSchema> | null>>;
}) => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const handleSearch = debounce((text: string) => setSearchText(text), 300);

    if (!standardTemplates && !customTemplates) return null;

    const createFromTemplate = async (template: ZodMetadataCollectionSchema) => {
        if (!Object.keys(template.items ?? {}).length)
            return enqueueSnackbar(t('custom-fields.no-fields-in-template'), {
                variant: 'warning',
            });

        const schema = new MetadataCollectionSchema(template.title, template.items ?? {}, template.description, template.target);
        await Data.put(schema);
        navigate(`/custom-fields/schemas/${schema._id}`);

        enqueueSnackbar(t('custom-fields.schema-create-template-success', { templateName: schema.title }), { variant: 'info' });
    };

    return (
        <Dialog open={true} onClose={() => closeTemplatesDialog(null)}>
            <Stack direction="column" justifyContent="space-between" alignItems="center" p={2} sx={{ width: 400, height: 600 }}>
                <TextField
                    onChange={e => handleSearch(e.target.value)}
                    label={t('custom-fields.search-templates')}
                    variant="outlined"
                    fullWidth
                    sx={{
                        mb: 2,
                    }}
                />
                <SimpleTab
                    tabs={[
                        {
                            tab: 0,
                            label: 'Custom Templates' as TranslationKey,
                            content: (
                                <Stack sx={{ overflowY: 'auto', flexGrow: 1, width: '100%' }}>
                                    <CollectionTemplateList
                                        createFromTemplate={createFromTemplate}
                                        templates={customTemplates}
                                        searchText={searchText}
                                    />
                                </Stack>
                            ),
                        },
                        {
                            tab: 1,
                            label: 'Standard Templates' as TranslationKey,
                            content: (
                                <Stack sx={{ overflowY: 'auto', flexGrow: 1, width: '100%' }}>
                                    <CollectionTemplateList
                                        createFromTemplate={createFromTemplate}
                                        templates={standardTemplates}
                                        searchText={searchText}
                                    />
                                </Stack>
                            ),
                        },
                    ]}
                    rest={{
                        flexGrow: 1,
                        width: '100%',
                    }}
                />
            </Stack>
            <DialogActions>
                <Button onClick={() => closeTemplatesDialog(null)} color="primary">
                    {t('general.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
