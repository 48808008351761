import { Box, Dialog, DialogTitle } from '@mui/material';
import type { MetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { validateMetadataCollectionSchema } from '@nexdynamic/squeegee-common';

import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { Data } from '../../../Data/Data';
import { t } from '../../../t';
import { MetadataCollectionSchemaEditForm } from './MetadataCollectionSchemaEditForm';

export const MetadataCollectionSchemaEditDialog = () => {
    const { schemaId } = useParams();
    const navigate = useNavigate();

    new URLSearchParams(window.location.search).get('editing') === 'true';
    if (!schemaId) {
        return <div>No schemaId found</div>;
    }

    const schema = Data.get<MetadataCollectionSchema>(schemaId);
    if (!schema) {
        return <div>No schema found</div>;
    }

    const handleBack = () => navigate('/custom-fields/schemas/' + schemaId);

    const editing = new URLSearchParams(window.location.search).get('editing') === 'true';

    return (
        <Dialog open={editing} onClose={handleBack} maxWidth="sm" fullWidth>
            <DialogTitle>{t('custom-fields.editing-schema')}</DialogTitle>
            <Formik
                initialValues={schema}
                validate={validateMetadataCollectionSchema}
                onSubmit={async values => {
                    await Data.put<MetadataCollectionSchema>({
                        ...values,
                    });
                    navigate('/custom-fields/schemas/' + schemaId);
                }}
            >
                <Box sx={{ m: 2 }}>
                    <MetadataCollectionSchemaEditForm schemaToEdit={schema} />
                </Box>
            </Formik>
        </Dialog>
    );
};
