import type { Job, StoredObject, StoredObjectResourceTypes } from '@nexdynamic/squeegee-common';
import { useEffect, useMemo, useState } from 'react';
import { Data } from '../../Data/Data';
import type { Subscription } from '../../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';

type ObjectType = Exclude<StoredObject, Job>;

type UseStoredObjectsOptions<ObjectType> = {
    in?: string[];
    sort?: (a: ObjectType, b: ObjectType) => number;
    watch?: boolean;
};

type Resource = Exclude<StoredObjectResourceTypes | '', 'jobs'>;

const getStoredObjects = <T extends ObjectType>(
    type: Resource,
    options: UseStoredObjectsOptions<T>,
    where?: Partial<T> | ((item: T) => boolean)
) => {
    let docs: T[] = [];

    if (options.in && where) console.warn('useStoredObjects: options.in and options.where are mutually exclusive');

    if (options.in) {
        docs = options.in.map(id => Data.get<T>(id)).filter(Boolean) as Array<T>;
    } else {
        docs = [...Data.all<T>(type, where)];
    }

    return docs;
};

const useStoredObjects = <TObjectType extends Exclude<StoredObject, Job>>(
    type: Resource,
    options: UseStoredObjectsOptions<TObjectType> = { watch: true },
    where?: Partial<TObjectType> | ((item: TObjectType) => boolean)
) => {
    const [docs, setDocs] = useState(getStoredObjects<TObjectType>(type, options, where));
    const sortedDocs = useMemo(() => (options.sort ? docs.sort(options.sort) : docs), [docs, options.in, options.sort]);

    useEffect(() => {
        const watch = options.watch ?? true;
        setDocs(getStoredObjects(type, options, where));

        const handleChange = () => {
            setDocs(getStoredObjects(type, options, where));
        };

        let subscription: Subscription | undefined;
        if (subscription) subscription.dispose();
        if (watch) {
            subscription = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
                if (event.hasAnyType(type as StoredObjectResourceTypes)) {
                    const docs = event.hasAnyType(type as StoredObjectResourceTypes);
                    if (docs) handleChange();
                }
            });
        }

        return () => subscription && subscription.dispose();
    }, [type, options.watch, options.in, where]);

    return sortedDocs;
};

export default useStoredObjects;
