import { Button, Chip, ListItem, ListItemText, Paper, Stack } from '@mui/material';
import type { MetadataItemSchema, MetadataLiteralTypes } from '@nexdynamic/squeegee-common';
import useTranslation from '../../hooks/useTranslation';

export const ItemSchemaCloneList = ({
    itemSchemas,
    cloneField,
}: {
    itemSchemas: { [itemIndex: number]: MetadataItemSchema<MetadataLiteralTypes> };
    cloneField: (field: MetadataItemSchema<MetadataLiteralTypes>) => void;
}) => {
    const { t } = useTranslation();

    return (
        <Stack spacing={1}>
            {Object.entries(itemSchemas).map(([itemIdIndex, itemSchema]) => (
                <Paper component={ListItem} key={itemIdIndex}>
                    <Stack direction={'row'} spacing={2} alignItems="center" width="100%">
                        <Chip label={t(`custom-fields.type-${itemSchema.type}`)} size="small" />

                        <ListItemText primary={itemSchema.title} secondary={itemSchema.description} />
                        <Stack direction="row" spacing={1}>
                            <Button onClick={() => cloneField(itemSchema)}>{t('custom-fields.clone-field')}</Button>
                        </Stack>
                    </Stack>
                </Paper>
            ))}
        </Stack>
    );
};
