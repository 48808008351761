import type { AvailableMetadataTarget, Job, StoredObject } from '@nexdynamic/squeegee-common';
import { NotifyUserMessage } from '../../../../Notifications/NotifyUserMessage';
import { resolveSchemasToComplete } from '../resolveSchemasToComplete';
import { completeMetadataCollectionSchemas } from './completeMetadataCollectionSchemas';

type CompleteMetadataProps = {
    targetObject: StoredObject | Job;

    type: AvailableMetadataTarget;
    customerId?: string;
    displayNoSchemasMessage?: boolean;
};

export const completeMetadata = async ({
    targetObject,
    type,
    customerId,
    displayNoSchemasMessage,
}: CompleteMetadataProps): Promise<boolean> => {
    const schemasToComplete = resolveSchemasToComplete({
        targetId: targetObject._id,
        type,
        customerId: customerId,
    });
    if (schemasToComplete.length === 0) {
        displayNoSchemasMessage && new NotifyUserMessage('custom-fields.no-fields-to-complete');
        return true;
    }

    const result = await completeMetadataCollectionSchemas({
        targetObject: targetObject,
        schemasToComplete,
    });

    if (!result) return false;

    return true;
};
