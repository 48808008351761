import { Chip } from '@mui/material';
import type { ArrayMetadataTypes, MetadataItemInstance, MetadataItemSchema } from '@nexdynamic/squeegee-common';
import { CustomFieldListItem } from '../../components/CustomFieldListItem';

type CompletedItemSelectFieldProps = {
    item: MetadataItemInstance<ArrayMetadataTypes>;
    itemSchema: MetadataItemSchema<ArrayMetadataTypes>;
    itemSchemaKey: number;
    selectItemSchema: (key: number) => void;
};
export const CompletedSelectField = ({ item, itemSchema, itemSchemaKey }: CompletedItemSelectFieldProps) => {
    return (
        <CustomFieldListItem
            item={item}
            itemSchema={itemSchema}
            itemSchemaKey={itemSchemaKey}
            slots={{
                contentAfter: (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '0.5rem',
                            }}
                        >
                            {(item.value || [])?.map((option, index) => (
                                <Chip
                                    key={index}
                                    label={option}
                                    color="primary"
                                    variant="outlined"
                                    sx={{
                                        margin: 0.5,
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                    }}
                                />
                            ))}
                        </div>
                    </>
                ),
            }}
        />
    );
};
