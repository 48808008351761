import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { AureliaReactComponentDialog } from '../../../../Dialogs/AureliaReactComponentDialog';
import { Logger } from '../../../../Logger';
import type { MetadataCompleteDialogProps } from './CustomFieldsCompletionDialog';
import { CustomFieldsCompletionDialog } from './CustomFieldsCompletionDialog';

export const completeMetadataCollectionSchemas = async ({ targetObject, schemasToComplete }: MetadataCompleteDialogProps) => {
    try {
        const dialog = new AureliaReactComponentDialog<boolean, MetadataCompleteDialogProps>({
            component: CustomFieldsCompletionDialog,
            dialogTitle: schemasToComplete?.length === 1 ? (schemasToComplete[0].title as TranslationKey) : 'custom-fields.complete-fields',
            componentProps: {
                targetObject,
                schemasToComplete,
            },
            isSecondaryView: true,
        });

        dialog.saveButton = {
            save: () => dialog.ok(true),
            saveText: 'general.finish',
        };

        const metadata = await dialog.show();

        if (dialog.cancelled) return false;
        if (!metadata) return false;

        return true;
    } catch (error) {
        Logger.error('Error while handling metadata collections.', error);
    }
};
