import { Autocomplete, Chip, ListItem, Popover, Stack, TextField, useTheme } from '@mui/material';
import type { MetadataCollectionSchema, TranslationKey, ZodMetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { debounce, uuid } from '@nexdynamic/squeegee-common';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import Text from '../../components/Text';
import useTranslation from '../../hooks/useTranslation';
import { MetadataCollectionService } from '../MetadataCollectionService';
import { customFieldTemplates } from '../Templates/collectionSchemaTemplates';

export const CustomFieldsCollectionSearchPopover = ({
    setPopoverOpen,
    viewingSchemaId,
    setCollectionSchemaForCloning,
}: {
    setPopoverOpen: Dispatch<SetStateAction<boolean>>;
    viewingSchemaId: string;
    setCollectionSchemaForCloning: Dispatch<SetStateAction<MetadataCollectionSchema | null>>;
}) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const theme = useTheme();

    const collections = MetadataCollectionService.getAll()
        .concat(MetadataCollectionService.getTemplates())
        .concat(customFieldTemplates as any);

    const filteredCollections = collections.filter(schema => {
        if (schema._id === viewingSchemaId) return false;
        if (Object.keys(schema.items ?? {}).length < 1) return false;
        const search = searchValue.toLowerCase();
        if (!search) return true;
        return (
            schema.title?.toLowerCase().includes(search) ||
            schema.description?.toLowerCase().includes(search) ||
            schema.target?.toLowerCase().includes(search)
        );
    });

    const handleClose = () => {
        setSearchValue('');
        setPopoverOpen(false);
    };

    const handleSelect = (_event: React.MouseEvent<HTMLElement>, schema: MetadataCollectionSchema) => {
        handleClose();
        setCollectionSchemaForCloning(schema);
    };

    if (!filteredCollections?.length) {
        setPopoverOpen(false);
        return null;
    }
    return (
        <>
            <Popover
                open={true}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                anchorEl={document.getElementById('main-scroll-container') ?? undefined}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                sx={{
                    backgroundColor: theme.palette.action.disabledBackground,
                }}
            >
                <Autocomplete
                    sx={{
                        padding: 1,
                        minWidth: 500,
                    }}
                    options={filteredCollections}
                    onInputChange={(_, newValue) => debounce(() => setSearchValue(newValue), 300)}
                    onChange={handleSelect}
                    getOptionLabel={(option: MetadataCollectionSchema | ZodMetadataCollectionSchema) => option.title}
                    renderOption={(props, option: MetadataCollectionSchema | ZodMetadataCollectionSchema) => {
                        const items = Object.keys(option.items ?? {}).length;
                        const label = `${option.title} (${items} ${t('general.fields')})`;
                        return (
                            <ListItem {...props} key={'_id' in option ? option._id : uuid()}>
                                <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} gap={1}>
                                    <Text text={label as TranslationKey} />
                                    <Chip
                                        color={option.isTemplate ? 'secondary' : 'primary'}
                                        label={option.isTemplate ? t('general.template') : t('general.standard')}
                                        size="small"
                                    />
                                </Stack>
                            </ListItem>
                        );
                    }}
                    renderInput={params => (
                        <TextField {...params} label={t('custom-fields.search-existing-collection-to-choose-items-from')} />
                    )}
                />
            </Popover>
        </>
    );
};
