import { InputAdornment, Stack, TextField } from '@mui/material';
import { type MetadataItemInstance } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import useTranslation from '../../../../../hooks/useTranslation';
import { FieldFormHeader } from '../../../dialog/components/FieldFormHeader';
import type { MetadataFormProps } from '../getFormForMetadata';

export const TextMetadataItemForm: React.FC<MetadataFormProps<'text'>> = ({ itemSchema, saveValues, itemInstance, skipItem }) => {
    const { t } = useTranslation();

    const [values, setValues] = useState<MetadataItemInstance<'text'>>(
        itemInstance ?? {
            value: '',
            notes: '',
        }
    );

    const handleValueChange = (field: keyof MetadataItemInstance<'text'>) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValues = {
            ...values,
            [field]: event.target.value,
        };

        setValues(newValues);
    };

    return (
        <Stack spacing={2}>
            <FieldFormHeader
                itemSchema={itemSchema}
                itemInstance={itemInstance}
                values={values}
                setValues={setValues}
                skipItem={skipItem}
                saveValues={saveValues}
            />

            <TextField
                label={t('general.value')}
                value={values.value}
                onChange={handleValueChange('value')}
                disabled={values.skipped}
                required={!itemSchema.skippable}
                InputProps={{
                    startAdornment:
                        itemSchema.settings?.prefix !== undefined ? (
                            <InputAdornment position="start">{itemSchema.settings.prefix}</InputAdornment>
                        ) : undefined,
                    endAdornment:
                        itemSchema.settings?.suffix !== undefined ? (
                            <InputAdornment position="end">{itemSchema.settings.suffix}</InputAdornment>
                        ) : undefined,
                }}
            />

            <TextField
                label={t('general.notes')}
                value={values.notes}
                onChange={handleValueChange('notes')}
                multiline
                rows={4}
                inputProps={{ maxLength: 255 }}
            />
        </Stack>
    );
};
