import type { SxProps } from '@mui/material';
import { Box, Tab, Tabs } from '@mui/material';
import type React from 'react';
import { useEffect, useState } from 'react';

export type SimpleTabType = { tab: number; label: string; content: JSX.Element | React.ReactElement };
export type SimpleTabsType = Array<SimpleTabType>;
export const SimpleTab = ({ tabs, detectTabChange, rest }: { tabs: SimpleTabsType; detectTabChange?: () => void; rest?: SxProps }) => {
    const [activeTab, setActiveTab] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (event && newValue !== activeTab) {
            setActiveTab(newValue);
        }
    };

    useEffect(() => {
        detectTabChange && detectTabChange();
    }, [activeTab]);

    return (
        <Box sx={{ width: '100%', ...rest }}>
            <Tabs value={activeTab} onChange={handleChange} aria-label="scalable tabs" variant="fullWidth">
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} />
                ))}
            </Tabs>
            <Box sx={{ padding: 2 }}>{tabs.map((tab, index) => activeTab === index && <div key={index}>{tab.content}</div>)}</Box>
        </Box>
    );
};
