import type { ZodMetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { t } from '../../../t';

const externalVanChecklistTemplate: ZodMetadataCollectionSchema = {
    title: t('custom-fields.van-checklist-template'),
    description: t('custom-fields.van-checklist-template-description'),
    target: 'job-occurrence-completion',
    isTemplate: true,
    items: {
        [0]: {
            title: t('custom-fields.van-checklist-template-item-make-and-model'),
            description: t('custom-fields.van-checklist-template-item-make-and-model-description'),
            type: 'text',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [1]: {
            title: t('custom-fields.van-checklist-template-item-registration'),
            description: t('custom-fields.van-checklist-template-item-registration-description'),
            type: 'text',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [2]: {
            title: t('custom-fields.van-checklist-template-mileage'),
            description: t('custom-fields.van-checklist-template-mileage-description'),
            type: 'number',
            notesRequired: 0,
            skippable: false,
            active: true,
            settings: {
                suffix: 'mi',
            },
        },
        [3]: {
            title: t('custom-fields.van-checklist-template-date-of-check'),
            description: t('custom-fields.van-checklist-template-date-of-check-description'),
            type: 'text',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [4]: {
            title: t('custom-fields.van-checklist-template-cleanliness'),
            description: t('custom-fields.van-checklist-template-cleanliness-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [5]: {
            title: t('custom-fields.van-checklist-template-windscreen'),
            description: t('custom-fields.van-checklist-template-windscreen-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [6]: {
            title: t('custom-fields.van-checklist-template-wiper-blades'),
            description: t('custom-fields.van-checklist-template-wiper-blades-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [7]: {
            title: t('custom-fields.van-checklist-template-lights-front'),
            description: t('custom-fields.van-checklist-template-lights-front-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [8]: {
            title: t('custom-fields.van-checklist-template-lights-rear'),
            description: t('custom-fields.van-checklist-template-lights-rear-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [9]: {
            title: t('custom-fields.van-checklist-template-oil-level'),
            description: t('custom-fields.van-checklist-template-oil-level-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [10]: {
            title: t('custom-fields.van-checklist-template-screenwash'),
            description: t('custom-fields.van-checklist-template-screenwash-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },

        [11]: {
            title: t('custom-fields.van-checklist-template-tyre-tread-front-left'),
            description: t('custom-fields.van-checklist-template-tyre-tread-front-left-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [12]: {
            title: t('custom-fields.van-checklist-template-tyre-tread-front-right'),
            description: t('custom-fields.van-checklist-template-tyre-tread-front-right-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [13]: {
            title: t('custom-fields.van-checklist-template-tyre-tread-rear-left'),
            description: t('custom-fields.van-checklist-template-tyre-tread-rear-left-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [14]: {
            title: t('custom-fields.van-checklist-template-tyre-tread-rear-right'),
            description: t('custom-fields.van-checklist-template-tyre-tread-rear-right-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
    },
};

const internalVanChecklistTemplate: ZodMetadataCollectionSchema = {
    title: t('custom-fields.van-checklist-template-internal'),
    description: t('custom-fields.van-checklist-template-internal-description'),
    target: 'job-occurrence-completion',
    isTemplate: true,
    items: {
        [0]: {
            title: t('custom-fields.van-checklist-template-internal-item-cleanliness'),
            description: t('custom-fields.van-checklist-template-internal-item-cleanliness-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [1]: {
            title: t('custom-fields.van-checklist-template-internal-item-full-first-aid-kit'),
            description: t('custom-fields.van-checklist-template-internal-item-full-first-aid-kit-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [2]: {
            title: t('custom-fields.van-checklist-template-internal-item-seatbelts'),
            description: t('custom-fields.van-checklist-template-internal-item-seatbelts-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [3]: {
            title: t('custom-fields.van-checklist-template-internal-item-air-conditioning'),
            description: t('custom-fields.van-checklist-template-internal-item-air-conditioning-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [4]: {
            title: t('custom-fields.van-checklist-template-internal-item-radio'),
            description: t('custom-fields.van-checklist-template-internal-item-radio-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
    },
};

const userHealthScreeningTemplate: ZodMetadataCollectionSchema = {
    title: t('custom-fields.user-health-screening-template'),
    description: t('custom-fields.user-health-screening-template-description'),
    target: 'accountuser',
    isTemplate: true,
    items: {
        [0]: {
            title: t('custom-fields.user-health-screening-template-item-date-of-birth'),
            type: 'text',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [1]: {
            title: t('custom-fields.user-health-screening-template-item-name'),
            type: 'text',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [2]: {
            title: t('custom-fields.user-health-screening-template-item-height'),
            description: t('custom-fields.user-health-screening-template-item-height-description'),
            type: 'number',
            notesRequired: 0,
            skippable: false,
            active: true,
            settings: {
                suffix: 'cm',
            },
        },
        [3]: {
            title: t('custom-fields.user-health-screening-template-item-weight'),
            description: t('custom-fields.user-health-screening-template-item-weight-description'),
            type: 'number',
            notesRequired: 0,
            skippable: false,
            active: true,
            settings: {
                suffix: 'kg',
            },
        },
        [4]: {
            title: t('custom-fields.user-health-screening-template-item-sex'),
            description: t('custom-fields.user-health-screening-template-item-sex-description'),
            type: 'select',
            notesRequired: 0,
            skippable: false,
            active: true,
            settings: {
                selectOptions: [t('general.male'), t('general.female')],
            },
        },
        [5]: {
            title: t('custom-fields.user-health-screening-template-item-medical-conditions'),
            description: t('custom-fields.user-health-screening-template-item-medical-conditions-description'),
            type: 'select',
            notesRequired: 0,
            skippable: false,
            active: true,
            settings: {
                selectOptions: [t('general.none'), 'Hepatitis A, B or C', 'HIV'],
                selectMultiple: true,
            },
        },
        [6]: {
            title: t('custom-fields.user-health-screening-template-item-suffered-from'),
            description: t('custom-fields.user-health-screening-template-item-suffered-from-description'),
            type: 'select',
            notesRequired: 0,
            skippable: false,
            active: true,
            settings: {
                selectOptions: [
                    t('general.none'),
                    t('custom-field.template-headaches'),
                    t('custom-field.template-fatigue'),
                    t('custom-field.template-anxiety'),
                    t('custom-field.template-depression'),
                ],
                selectMultiple: true,
            },
        },
        [7]: {
            title: t('custom-fields.user-health-screening-template-item-relatives-suffered-from'),
            description: t('custom-fields.user-health-screening-template-item-relatives-suffered-from-description'),
            type: 'select',
            notesRequired: 0,
            skippable: false,
            active: true,
            settings: {
                selectOptions: [t('general.none'), t('custom-field.template-heart-disease'), t('custom-field.template-diabetes')],
                selectMultiple: true,
            },
        },
        [8]: {
            title: t('custom-fields.user-health-screening-template-item-back-pain'),
            description: t('custom-fields.user-health-screening-template-item-back-pain-description'),
            type: 'boolean',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [9]: {
            title: t('custom-fields.user-health-screening-template-item-other-injuries'),
            description: t('custom-fields.user-health-screening-template-item-other-injuries-description'),
            type: 'text',
            notesRequired: 3,
            skippable: false,
            active: true,
        },
        [10]: {
            title: t('custom-fields.user-health-screening-template-item-medication'),
            description: t('custom-fields.user-health-screening-template-item-medication-description'),
            type: 'select',
            notesRequired: 0,
            skippable: false,
            active: true,
            settings: {
                selectOptions: [t('general.none'), t('custom-field.template-medications'), t('custom-field.template-supplements')],
                selectMultiple: true,
            },
        },
        [11]: {
            title: t('custom-fields.user-health-screening-template-item-physical-fitness'),
            description: t('custom-fields.user-health-screening-template-item-physical-fitness-description'),
            type: 'select',
            notesRequired: 0,
            skippable: false,
            active: true,
            settings: {
                selectOptions: ['Poor', 'Fair', 'Good', 'Excellent'],
            },
        },
        [12]: {
            title: t('custom-fields.user-health-screening-template-item-work-related-injury-ill-health'),
            description: t('custom-fields.user-health-screening-template-item-work-related-injury-ill-health-description'),
            type: 'text',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [13]: {
            title: t('custom-fields.user-health-screening-template-item-anything-else-we-should-know'),
            description: t('custom-fields.user-health-screening-template-item-anything-else-we-should-know-description'),
            type: 'text',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [14]: {
            title: t('custom-fields.user-health-screening-template-item-signature'),
            description: t('custom-fields.user-health-screening-template-item-signature-description'),
            type: 'signature',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
    },
};

const riskAssessmentTemplate: ZodMetadataCollectionSchema = {
    title: t('custom-fields.risk-assessment-template'),
    description: t('custom-fields.risk-assessment-template-description'),
    target: 'job-occurrence-completion',
    isTemplate: true,
    items: {
        [0]: {
            title: t('custom-fields.risk-assessment-template-item-date'),
            type: 'text',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [1]: {
            title: t('custom-fields.risk-assessment-template-item-job-location'),
            type: 'text',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [2]: {
            title: t('custom-fields.risk-assessment-template-item-describe-hazard'),
            type: 'text',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [3]: {
            title: t('custom-fields.risk-assessment-template-item-activity'),
            type: 'text',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [4]: {
            title: t('custom-fields.risk-assessment-template-item-actions'),
            type: 'text',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [5]: {
            title: t('custom-fields.risk-assessment-template-item-risk-level'),
            type: 'select',
            notesRequired: 0,
            skippable: false,
            active: true,
            settings: {
                selectOptions: ['Safe', 'Low', 'Medium', 'High', 'Dangerous'],
                selectMultiple: false,
            },
        },
        [6]: {
            title: t('custom-fields.risk-assessment-template-item-images'),
            description: t('custom-fields.risk-assessment-template-item-images-description'),
            type: 'attachment',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
        [7]: {
            title: t('custom-fields.risk-assessment-template-item-signature'),
            type: 'signature',
            notesRequired: 0,
            skippable: false,
            active: true,
        },
    },
};

export const customFieldTemplates = [
    externalVanChecklistTemplate,
    internalVanChecklistTemplate,
    userHealthScreeningTemplate,
    riskAssessmentTemplate,
];
