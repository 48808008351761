import { Chip, List, ListItem, ListItemSecondaryAction, ListItemText, Paper } from '@mui/material';
import { ApplicationState } from '../../../ApplicationState';
import type { CustomFieldDataStructure } from './CustomFieldDialog';

export const GlobalSchemaList = ({ schemas }: { schemas: CustomFieldDataStructure }) => {
    if (!schemas.size) return null;
    return (
        <List>
            {Array.from(schemas.values()).map(schemaAndIsEnabled => (
                <Paper key={schemaAndIsEnabled.schema._id} sx={{ margin: 1 }}>
                    <ListItem>
                        <ListItemText primary={schemaAndIsEnabled.schema.title} />
                        <ListItemSecondaryAction>
                            <Chip label={ApplicationState.localise('general.enabled')} color="success" />
                        </ListItemSecondaryAction>
                    </ListItem>
                </Paper>
            ))}
        </List>
    );
};
