import IncompleteIcon from '@mui/icons-material/CancelRounded';
import CompleteIcon from '@mui/icons-material/CheckCircleRounded';
import SkipIcon from '@mui/icons-material/RotateRightRounded';
import { Chip, type ChipProps } from '@mui/material';
import type { MetadataItemInstance, MetadataLiteralTypes } from '@nexdynamic/squeegee-common';
import useTranslation from '../../../../hooks/useTranslation';

type Props = {
    item: MetadataItemInstance<MetadataLiteralTypes> | undefined;
    chipProps?: ChipProps;
};

export const FieldCompletionStatusChip: React.FC<Props> = ({ item, chipProps }) => {
    const { t } = useTranslation();

    const complete = item?.value !== undefined && item.value !== null && item.value !== '';
    const skipped = item?.skipped;

    return (
        <Chip
            label={skipped ? t('general.skipped') : complete ? t('general.complete') : t('general.incomplete')}
            icon={skipped ? <SkipIcon /> : complete ? <CompleteIcon /> : <IncompleteIcon />}
            color={skipped ? 'warning' : complete ? 'success' : 'error'}
            size="small"
            {...chipProps}
        />
    );
};
