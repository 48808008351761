import { Box, FormControlLabel, Stack, Switch, TextField, Typography, useTheme } from '@mui/material';
import type { ZodMetadataItemSchema } from '@nexdynamic/squeegee-common';
import { Field, useFormikContext } from 'formik';

import useTranslation from '../../../hooks/useTranslation';
import { getAvailableSettingsForCustomField } from './getAvailableSettingsForCustomField';

export const CustomFieldSettingsComponent = () => {
    const { t } = useTranslation();
    const { values, setFieldValue } = useFormikContext<ZodMetadataItemSchema>();
    const { type } = values;
    const theme = useTheme();

    const settings = getAvailableSettingsForCustomField(type);

    if (!settings) return null;

    const keys = Object.keys(settings);

    const handleFieldChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setFieldValue(`settings.${field}`, newValue);
    };

    return (
        <Box
            sx={{
                border: '1px solid',
                borderColor: theme.palette.info.dark,
                borderRadius: 1,
                padding: 2,
            }}
        >
            <Typography variant="h6">{t('custom-fields.additional-settings')}</Typography>

            <Stack spacing={1}>
                {keys.map(key => {
                    const setting = settings[key];
                    const fieldType = typeof setting as string | number | boolean | string[] | undefined;

                    if (fieldType === 'boolean') {
                        return (
                            <FormControlLabel
                                key={key}
                                control={
                                    <Field component={Switch} type="checkbox" name={`settings.${key}`} onChange={handleFieldChange(key)} />
                                }
                                label={t(`custom-fields.settings-${key}`)}
                            />
                        );
                    } else {
                        return (
                            <Field
                                key={key}
                                component={TextField}
                                name={`settings.${key}`}
                                label={t(`custom-fields.settings-${key}`)}
                                type={fieldType === 'number' ? 'number' : 'text'}
                                onChange={handleFieldChange(key)}
                            />
                        );
                    }
                })}
            </Stack>
        </Box>
    );
};
