import TemplateIcon from '@mui/icons-material/FileCopy';
import { Chip, ListItemIcon, ListItemText, Stack } from '@mui/material';
import type { ZodMetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { t } from '../../../t';

export const CollectionTemplateList = ({
    templates,
    createFromTemplate,
    searchText,
}: {
    templates: Array<ZodMetadataCollectionSchema> | null;
    createFromTemplate: (template: ZodMetadataCollectionSchema) => void;
    searchText: string;
}) => {
    if (!templates) return null;
    const filteredTemplates = templates.filter(template => {
        const title = template.title.toLowerCase();
        const description = template.description?.toLowerCase() || '';
        const search = searchText.toLowerCase();
        return title.includes(search) || description.includes(search);
    });

    return (
        <Stack spacing={2}>
            {filteredTemplates.map((template, index) => (
                <Stack
                    key={index}
                    onClick={() => createFromTemplate(template)}
                    sx={{ width: '100%', cursor: 'pointer' }}
                    direction={'row'}
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    p={1}
                    borderRadius={1}
                    border={1}
                    borderColor="grey.300"
                >
                    <ListItemIcon>
                        <TemplateIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={template.title}
                        secondary={t('custom-fields.collection-list-fields', { count: Object.keys(template.items ?? {}).length })}
                    />

                    <Chip
                        label={t(`custom-fields.target-${template.target}`)}
                        color="primary"
                        size="small"
                        sx={{ backgroundColor: 'primary.main', color: 'white' }}
                    />
                </Stack>
            ))}
        </Stack>
    );
};
