import BinIcon from '@mui/icons-material/Delete';
import { Chip, TextField } from '@mui/material';
import type { ZodMetadataItemSchema } from '@nexdynamic/squeegee-common';
import { useFormikContext } from 'formik';
import type { KeyboardEvent } from 'react';
import { useState } from 'react';
import { t } from '../../../t';

export const AddSelectOptionsField = () => {
    const { values, setFieldValue, errors, touched } = useFormikContext<ZodMetadataItemSchema>();
    const [textValue, setTextValue] = useState('');

    if (!values.type || values.type !== 'select') return null;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue(event.target.value);
    };

    const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            if (!textValue) {
                return;
            }

            const newOptions = [...(values.settings?.selectOptions || []), textValue.trim()];

            await setFieldValue('settings.selectOptions', newOptions);

            setTextValue('');
        }
    };

    const removeOption = (index: number) => {
        setFieldValue(
            'settings.selectOptions',
            values.settings.selectOptions.filter((_: any, i: number) => i !== index)
        );
    };
    return (
        <>
            <TextField
                sx={{ display: 'flex', flexWrap: 'wrap' }}
                type="text"
                onKeyDown={handleKeyDown}
                label={t('custom-field.select-options-field')}
                onChange={handleChange}
                InputProps={{
                    value: textValue,
                }}
                multiline
                error={Boolean(touched.settings && errors.settings)}
                helperText={touched.settings && errors.settings ? String(errors.settings) : ''}
            />
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '0.5rem',
                }}
            >
                {values.settings?.selectOptions?.map((option, index) => (
                    <Chip
                        key={index}
                        label={option}
                        onDelete={() => removeOption(index)}
                        deleteIcon={<BinIcon color="error" />}
                        color="primary"
                        variant="outlined"
                        sx={{
                            margin: 0.5,
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}
                    />
                ))}
            </div>
        </>
    );
};
