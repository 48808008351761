import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import { Chip, List, ListItem, ListItemButton, ListItemText, Paper, Stack } from '@mui/material';
import { EmptyList } from '@nexdynamic/nex-ui-react';
import type { MetadataCollectionSchema, MetadataItemSchema, MetadataLiteralTypes } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router-dom';
import { ApplicationState } from '../../../ApplicationState';
import { t } from '../../../t';

export const MetadataItemList = ({ schema }: { schema: MetadataCollectionSchema }) => {
    if (!Object.keys(schema.items).length) return <EmptyList text={t('custom-fields.no-items')} sx={{ mt: 1 }} />;

    return (
        <List component={Stack} spacing={1}>
            {Object.keys(schema.items).map(keyString => {
                const key = Number(keyString);
                const item = schema.items[key];
                return <MetadataItemListItem key={key} item={item} itemSchemaKey={key} schema={schema} />;
            })}
        </List>
    );
};

export const MetadataItemListItem = ({
    item,
    itemSchemaKey,
    schema,
}: {
    item: MetadataItemSchema<MetadataLiteralTypes>;
    itemSchemaKey: number;
    schema: MetadataCollectionSchema;
}) => {
    const navigate = useNavigate();

    const handleEditSelect = () => {
        navigate(`/custom-fields/schemas/items/edit/${schema._id}/${itemSchemaKey}`);
    };

    return (
        <ListItem component={Paper} secondaryAction={<ChevronRightIcon />} disablePadding>
            <ListItemButton sx={{ opacity: item.active ?? true ? 1 : 0.5, minHeight: 64 }} onClick={() => handleEditSelect()}>
                <ListItemText
                    primary={item.title}
                    secondary={item.description}
                    secondaryTypographyProps={{
                        sx: { textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
                    }}
                />
                {!item.active && (
                    <Chip style={{ margin: 1 }} size="small" label={t('custom-fields.inactive-tag')} color="error" variant="outlined" />
                )}
                {!!item.type && (
                    <Chip
                        style={{ margin: 1 }}
                        size="small"
                        label={ApplicationState.localise(`custom-fields.type-${item.type}`)}
                        color="info"
                        variant="outlined"
                    />
                )}
            </ListItemButton>
        </ListItem>
    );
};
