import type {
    MetadataCollectionSchema,
    MetadataItemInstance,
    MetadataItemSchema,
    MetadataLiteralTypes,
    StoredObject,
} from '@nexdynamic/squeegee-common';
import { MetadataCollectionValue, getMetadataCollectionValuesId, notNullUndefinedEmptyOrZero } from '@nexdynamic/squeegee-common';
import React, { useContext, useState } from 'react';
import { Data } from '../../../../Data/Data';
import { useMetadataCompleteRouter } from '../dialog/CustomFieldsCompletionDialog';

export type MetadataContextType = {
    collectionSchemas: Array<MetadataCollectionSchema>;
    collectionValues: Map<string, MetadataCollectionValue>;
    selectCollectionSchema: (collectionId: string | null) => Promise<void>;
    selectedCollectionSchema: MetadataCollectionSchema | null;
    selectedCollectionValue: MetadataCollectionValue | null;
    setSelectedCollectionValue: (collectionValue: MetadataCollectionValue) => void;
    selectItemSchema: (itemId: number | null) => Promise<void>;
    selectedItemSchema: MetadataItemSchema<MetadataLiteralTypes> | null;
    targetObject: StoredObject;
    navigateBack: () => void;
    itemSchemaKey: number | null;
    totalCollectionSchemas?: number;
    setBooleanInstanceValue: (itemSchemaKey: number, value: boolean | undefined) => void;
    skipItem: (itemSchemaKey: number) => void;
};

export const MetadataContext = React.createContext<MetadataContextType>({
    collectionSchemas: [],
    collectionValues: new Map<string, MetadataCollectionValue>(),
    selectCollectionSchema: async () => undefined,
    selectedCollectionSchema: null,
    selectedCollectionValue: null,
    setSelectedCollectionValue: () => undefined,
    selectItemSchema: async () => undefined,
    selectedItemSchema: null,
    targetObject: {} as StoredObject,
    navigateBack: () => undefined,
    itemSchemaKey: null,
    totalCollectionSchemas: undefined,
    setBooleanInstanceValue: () => undefined,
    skipItem: () => undefined,
});

export const useMetadataCompleteContext = () => {
    const context = useContext(MetadataContext);
    if (!context) {
        throw new Error('useMetadataCompleteContext must be used within a MetadataCompleteContextProvider');
    }
    return context;
};

export const MetadataCompleteContextProvider = ({
    relatedObject: targetObject,
    schemasToComplete,
    children,
}: {
    relatedObject: StoredObject;
    schemasToComplete?: Array<MetadataCollectionSchema>;
    children: React.ReactNode;
}) => {
    const [selectedCollectionSchema, setSelectedCollectionSchema] = useState<MetadataCollectionSchema | null>(
        schemasToComplete?.length === 1 ? schemasToComplete[0] : null
    );

    const collectionValuesForSchemasToComplete = schemasToComplete
        ?.map(schema => {
            const id = getMetadataCollectionValuesId({ targetObjectId: targetObject._id, valuesSchemaId: schema._id });
            return Data.get<MetadataCollectionValue>(id);
        })
        .filter(notNullUndefinedEmptyOrZero);

    const collectionValues = new Map(collectionValuesForSchemasToComplete?.map(collection => [collection.schemaId, collection]));

    const [selectedCollectionValue, setSelectedCollectionValue] = useState<MetadataCollectionValue | null>(
        selectedCollectionSchema ? collectionValues.get(selectedCollectionSchema._id) || null : null
    );
    const [itemSchema, setItemSchema] = useState<MetadataItemSchema<MetadataLiteralTypes> | null>(null);
    const [itemSchemaKey, setItemSchemaKey] = useState<number | null>(null);
    const { navigateTo, navigateBack } = useMetadataCompleteRouter();

    if (!targetObject || !schemasToComplete?.length) return null;

    const selectCollectionSchema = async (id: string | null) => {
        if (id === null) {
            setSelectedCollectionSchema(null);
            return;
        }

        const schema = schemasToComplete.find(schema => schema._id === id);
        if (!schema) return;
        setSelectedCollectionSchema(schema);

        setSelectedCollectionValue(collectionValues.get(schema._id) || null);

        return;
    };

    const selectItemSchema = async (id: number | null) => {
        if (!selectedCollectionSchema) return;
        if (id === null) {
            setItemSchema(null);
            return;
        }

        setItemSchemaKey(id);
        setItemSchema(selectedCollectionSchema.items[id]);
        navigateTo('item-schema-form');
    };

    const navigateBackAndReset = () => {
        setItemSchema(null);
        setItemSchemaKey(null);
        navigateBack();
    };

    const setBooleanInstanceValue = (itemSchemaKey: number, value: boolean | undefined) => {
        if (!selectedCollectionSchema) return;

        const itemSchema = selectedCollectionSchema.items[itemSchemaKey];
        if (!itemSchema) return;
        if (itemSchema.type !== 'boolean') return;

        const collectionValue = selectedCollectionValue || new MetadataCollectionValue(targetObject._id, selectedCollectionSchema._id);
        const currentMetadataItemInstance = collectionValue.values[itemSchemaKey];
        const newMetadataItemInstance: MetadataItemInstance<MetadataLiteralTypes> = currentMetadataItemInstance
            ? { ...currentMetadataItemInstance, value }
            : { value };

        if (value === undefined) delete newMetadataItemInstance.value;
        delete newMetadataItemInstance.skipped;

        collectionValue.values[itemSchemaKey] = newMetadataItemInstance;
        Data.put(collectionValue);
    };

    const skipItem = (itemSchemaKey: number) => {
        if (!selectedCollectionSchema) return;

        if (itemSchemaKey === null) return;

        const collectionValue = selectedCollectionValue || new MetadataCollectionValue(targetObject._id, selectedCollectionSchema._id);
        const currentMetadataItemInstance = collectionValue.values[itemSchemaKey];
        const newMetadataItemInstance: MetadataItemInstance<MetadataLiteralTypes> = currentMetadataItemInstance
            ? { ...currentMetadataItemInstance, skipped: true }
            : { skipped: true };

        collectionValue.values[itemSchemaKey] = newMetadataItemInstance;
        Data.put(collectionValue);
        setSelectedCollectionValue(collectionValue);
    };
    return (
        <MetadataContext.Provider
            value={{
                collectionSchemas: schemasToComplete,
                collectionValues,
                selectCollectionSchema,
                selectedCollectionSchema: selectedCollectionSchema,
                selectedCollectionValue: selectedCollectionValue,
                setSelectedCollectionValue,
                selectItemSchema,
                selectedItemSchema: itemSchema,
                targetObject,
                navigateBack: navigateBackAndReset,
                itemSchemaKey,
                totalCollectionSchemas: schemasToComplete?.length,
                setBooleanInstanceValue,
                skipItem,
            }}
        >
            {children}
        </MetadataContext.Provider>
    );
};
