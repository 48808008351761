import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    type ChipProps,
    type ListItemButtonProps,
    type ListItemProps,
    type ListItemTextProps,
} from '@mui/material';
import type { MetadataItemInstance, MetadataItemSchema, MetadataLiteralTypes } from '@nexdynamic/squeegee-common';
import { useMetadataCompleteContext } from '../../context/useMetadataContext';
import { FieldCompletionStatusChip } from '../../dialog/components/FieldCompletionStatusChip';

type Props = {
    item: MetadataItemInstance<MetadataLiteralTypes>;
    itemSchema: MetadataItemSchema<MetadataLiteralTypes>;
    itemSchemaKey: number;
    primaryOverride?: string;
    secondaryOverride?: string;
    slots?: Partial<{
        secondaryAction: React.ReactNode;
        contentBefore: React.ReactNode;
        content: React.ReactNode;
        contentAfter: React.ReactNode;
        chip: React.ReactNode;
    }>;
    slotProps?: Partial<{
        listItem: ListItemProps;
        listItemButton: ListItemButtonProps;
        listItemText: ListItemTextProps;
        chip: ChipProps;
    }>;
};

export const CustomFieldListItem = ({ item, itemSchema, itemSchemaKey, primaryOverride, secondaryOverride, slots, slotProps }: Props) => {
    const { selectItemSchema, selectedCollectionSchema: collectionSchema } = useMetadataCompleteContext();
    if (!collectionSchema) return null;

    const handleClick = () => {
        selectItemSchema(itemSchemaKey);
    };

    const shouldShowValue = typeof item.value !== 'boolean' && !Array.isArray(item.value) && item.value;

    return (
        <ListItem
            component={Paper}
            secondaryAction={slots?.secondaryAction ?? <ChevronRightIcon />}
            disablePadding
            {...slotProps?.listItem}
        >
            <ListItemButton onClick={handleClick} {...slotProps?.listItemButton} sx={{ minHeight: 64, ...slotProps?.listItemButton?.sx }}>
                {slots?.contentBefore}

                {slots?.content || (
                    <ListItemText
                        primary={primaryOverride ?? itemSchema.title}
                        secondary={secondaryOverride ?? (shouldShowValue || itemSchema.description)}
                        {...slotProps?.listItemText}
                        sx={{
                            textWrap: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    />
                )}

                {slots?.contentAfter}

                {slots?.chip || <FieldCompletionStatusChip item={item} chipProps={slotProps?.chip} />}
            </ListItemButton>
        </ListItem>
    );
};
