import { useEffect, useState } from 'react';
import { Api } from '../../Server/Api';

export const useSqueegeeCredits = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [credits, setCredits] = useState<number>(0);
    const [inboundEmailCreditsCharge, setInboundEmailCreditsCharge] = useState<number>(0.01);

    useEffect(() => {
        const getInboundEmailCreditsCharge = async () => {
            try {
                const response = await Api.get<{ inboundEmailCreditsCharge: number }>(
                    null,
                    '/api/environment-settings/inboundEmailCreditsCharge'
                ).then(res => res?.data);
                if (!response?.inboundEmailCreditsCharge) return;
                setInboundEmailCreditsCharge(response.inboundEmailCreditsCharge);
            } catch (e) {
                console.error(e, 'Error in useSqueegeeCredits');
            }
        };
        getInboundEmailCreditsCharge();

        const poll = async () => {
            try {
                setLoading(true);
                const response = await Api.getSqueegeeCredits();
                const returnedCredits = response?.squeegeeCredits;
                setLoading(false);

                if (returnedCredits === undefined || returnedCredits === credits) return;

                setCredits(() => returnedCredits);
            } catch (e) {
                console.error(e, 'Error in useSqueegeeCredits');
            }
        };

        const timer = setInterval(() => {
            poll();
        }, 10000);
        poll();
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, []);

    return { loading, credits: credits ?? 0, inboundEmailCreditsCharge };
};
