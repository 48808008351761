import type { BooleanMetadataTypes } from '@nexdynamic/squeegee-common';
import { NotesRequirement } from '@nexdynamic/squeegee-common';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { t } from '../../../../../../t';
import { CustomFieldListItem } from '../../components/CustomFieldListItem';
import { DoneNotDoneSkippedToggle } from './CompletedCheckboxField/DoneNotDoneSkippedToggle';
import type { CompletedFieldProps } from './CompletedFieldProps';

export type CompletedItemBooleanFieldProps = CompletedFieldProps<BooleanMetadataTypes> & {
    setBooleanInstanceValue: (itemSchemaKey: number, value: boolean | undefined) => void;
};

export const CompletedCheckboxField = ({
    item,
    itemSchemaKey,
    itemSchema,
    selectItemSchema,
    setBooleanInstanceValue,
    skipItem,
}: CompletedItemBooleanFieldProps) => {
    const [isTrue, setIsTrue] = useState<boolean>(item.value === true);
    const [isFalse, setIsFalse] = useState<boolean>(item.value === false);
    const [isSkipped, setIsSkipped] = useState<boolean>(!!item.skipped);

    const setBoolean = (value: boolean) => {
        const notesRequired =
            itemSchema.notesRequired === NotesRequirement.Always ||
            (itemSchema.notesRequired === NotesRequirement.WhenSkippedOrFalsy && value === false);

        if (notesRequired) {
            enqueueSnackbar(t('custom-fields.validation-notes-required'), { variant: 'error' });
            return selectItemSchema(itemSchemaKey);
        }

        setIsTrue(value);
        setIsFalse(!value);
        setIsSkipped(false);
        setBooleanInstanceValue(itemSchemaKey, value);
    };

    const skipAndClear = () => {
        const notesRequired =
            itemSchema.notesRequired === NotesRequirement.Always ||
            itemSchema.notesRequired === NotesRequirement.WhenSkipped ||
            itemSchema.notesRequired === NotesRequirement.WhenSkippedOrFalsy;

        if (notesRequired) {
            enqueueSnackbar(t('custom-fields.validation-notes-required-skip'), { variant: 'error' });
            return selectItemSchema(itemSchemaKey);
        }

        setIsTrue(false);
        setIsFalse(false);
        setIsSkipped(true);
        setBooleanInstanceValue(itemSchemaKey, undefined);
        skipItem(itemSchemaKey);
    };

    return (
        <CustomFieldListItem
            item={item}
            itemSchema={itemSchema}
            itemSchemaKey={itemSchemaKey}
            slots={{
                chip: (
                    <DoneNotDoneSkippedToggle
                        itemSchema={itemSchema}
                        value={isSkipped ? 'skipped' : isTrue ? true : isFalse ? false : undefined}
                        onSkipClick={skipAndClear}
                        onFalseClick={() => setBoolean(false)}
                        onTrueClick={() => setBoolean(true)}
                        size="small"
                    />
                ),
            }}
            slotProps={{
                // WTF: disable hover effect when we hover one of the toggle buttons
                listItemButton: {
                    sx: {
                        '&:has(.MuiToggleButtonGroup-root:hover)': {
                            bgcolor: 'transparent',
                        },
                    },
                },
            }}
        />
    );
};
