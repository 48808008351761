import { ListItemText, Paper, Typography } from '@mui/material';
import type { Signature } from '@nexdynamic/squeegee-common';
import { Data } from '../../../../../../Data/Data';
import { CustomFieldListItem } from '../../components/CustomFieldListItem';
import type { CompletedFieldProps } from './CompletedFieldProps';

export const CompletedSignatureField = ({ itemSchema, item, itemSchemaKey }: CompletedFieldProps<'signature'>) => {
    const signature = (item.value && Data.get<Signature>(item.value)) || null;

    return (
        <CustomFieldListItem
            item={item}
            itemSchema={itemSchema}
            itemSchemaKey={itemSchemaKey}
            slots={{
                content: (
                    <ListItemText
                        primary={itemSchema.title}
                        secondary={
                            signature && (
                                <Paper variant="outlined">
                                    <img src={signature.signature} alt="Signature" />
                                    <Typography variant="body2">{signature.fullname}</Typography>
                                </Paper>
                            )
                        }
                    />
                ),
            }}
        />
    );
};
