import { Box, List } from '@mui/material';
import { EmptyList, SearchQueryField, useIsSmallScreen, useSearchQueryParams } from '@nexdynamic/nex-ui-react';
import type { ExpenseCategory } from '@nexdynamic/squeegee-common';
import { TagType, type Tag } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import useStoredObjects from '../../hooks/useStoredObjects';
import useTranslation from '../../hooks/useTranslation';
import ExpenseCategoiresIcon from './ExpenseCategoiresIcon';
import ExpenseCategoryItem from './ExpenseCategoryItem';

const searchFilter = (search: string, tag: Tag) => {
    return tag.description.toLowerCase().includes(search.toLowerCase()) && TagType.EXPENSE_CATEGORY === tag.type;
};

const ExpenseCategoiresList = () => {
    const {
        searchQuery: [search],
    } = useSearchQueryParams();

    const where = useMemo(() => {
        if (search) {
            return (tag: Tag) => searchFilter(search, tag);
        } else {
            return { type: TagType.EXPENSE_CATEGORY };
        }
    }, [search]);
    const categories = useStoredObjects<ExpenseCategory>('tags', undefined, where);
    const { t } = useTranslation();
    const isSmallScreen = useIsSmallScreen();

    const items = useMemo(() => {
        if (categories.length === 0)
            return (
                <EmptyList
                    text={search?.length ? t('expense-categories.search-empty', { searchTerm: search }) : t('expense-categories.empty')}
                    icon={<ExpenseCategoiresIcon />}
                />
            );
        return categories?.length ? (
            categories.map(category => {
                return (
                    <ExpenseCategoryItem
                        description={category.description}
                        id={category._id}
                        balanceLedgerId={category.balanceLedgerId}
                        key={category._id}
                    />
                );
            })
        ) : (
            <EmptyList text={t('expense-categories.empty')} icon={<ExpenseCategoiresIcon />} />
        );
    }, [categories, search]);

    return (
        <>
            <Box sx={{ width: '100%', maxWidth: isSmallScreen ? '100%' : 320 }}>
                <SearchQueryField />
            </Box>

            <List>{items}</List>
        </>
    );
};

export default ExpenseCategoiresList;
