import { Button, Paper, Stack, TextField } from '@mui/material';
import type { MetadataItemInstance, Signature } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import { Data } from '../../../../../../Data/Data';
import { SignatureDialog } from '../../../../../../Dialogs/Signatures/SignatureDialog';
import { t } from '../../../../../../t';
import type { MetadataFormProps } from '../getFormForMetadata';
import { FieldFormHeader } from '../../../dialog/components/FieldFormHeader';

export const SignatureMetadataItemForm: React.FC<MetadataFormProps<'signature'>> = ({ itemSchema, saveValues, itemInstance, skipItem }) => {
    const [signature, setSignature] = useState<Signature | undefined>(
        itemInstance?.value ? Data.get<Signature>(itemInstance.value) : undefined
    );

    const [values, setValues] = useState<MetadataItemInstance<'signature'>>({
        value: signature?._id,
        notes: itemInstance?.notes,
        skipped: itemInstance?.skipped,
    });

    const handleSignatureAcquisition = async () => {
        const dialog = new SignatureDialog('general.signature-required', 'general.ok');

        const signature = await dialog.show();

        if (dialog.cancelled) return;
        if (!signature) return;

        setSignature(signature);
        setValues({ ...values, value: signature._id });
    };

    const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, notes: e.target.value });
    };

    return (
        <Stack spacing={2}>
            <FieldFormHeader
                itemSchema={itemSchema}
                itemInstance={itemInstance}
                values={values}
                setValues={setValues}
                skipItem={skipItem}
                saveValues={saveValues}
            />

            {signature ? (
                <Button onClick={() => setSignature(undefined)}>{t('general.clear-signature')}</Button>
            ) : (
                <Button onClick={handleSignatureAcquisition} variant="contained">
                    {t('general.get-signature')}
                </Button>
            )}

            <Paper sx={{ backgroundColor: theme => (theme.palette.mode === 'dark' ? '#fff' : 'background.paper') }}>
                {signature && <img src={signature.signature} alt="signature" width="100%" />}
            </Paper>

            <TextField
                label={t('general.notes')}
                value={values.notes}
                onChange={handleNotesChange}
                multiline
                rows={4}
                inputProps={{ maxLength: 255 }}
            />
        </Stack>
    );
};
