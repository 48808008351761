import { Dialog, DialogTitle } from '@mui/material';
import type { MetadataLiteralTypes } from '@nexdynamic/squeegee-common';
import { MetadataCollectionSchema, MetadataItemSchema, NotesRequirement, zodMetadataItemSchema } from '@nexdynamic/squeegee-common';
import { Formik } from 'formik';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Data } from '../../../Data/Data';
import { t } from '../../../t';
import { CustomFieldsItemSchemaEditForm } from './CustomFieldsItemSchemaEditForm';

type Props = {
    schema: MetadataCollectionSchema;
};

export const CustomFieldsItemSchemaEditDialog = ({ schema }: Props) => {
    const navigate = useNavigate();

    const isNew = useMemo(() => new URLSearchParams(window.location.search).get('new') === 'true', []);

    const { itemIndex } = useParams();

    const initialValues = useMemo(() => {
        const stubItemSchema = new MetadataItemSchema<MetadataLiteralTypes>({
            title: '',
            description: '',
            type: 'boolean',
            notesRequired: NotesRequirement.Optional,
            skippable: false,
            active: true,
        });

        if (Number(itemIndex) === undefined) return stubItemSchema;

        const existingItemSchema = schema.items[Number(itemIndex)];
        return existingItemSchema || stubItemSchema;
    }, [itemIndex, schema]);

    const validate = (values: typeof initialValues) => {
        //ensure notes is a number;
        if (values.notesRequired) {
            values.notesRequired = Number(values.notesRequired);
        }

        const result = zodMetadataItemSchema.safeParse(values);
        if (!result.success) {
            const errors: Record<string, string> = {};
            if (!result.success) {
                for (const e of result.error.errors) {
                    if (e.path.length > 0) {
                        errors[e.path[0] as keyof typeof errors] = e.message;
                    }
                }

                return errors;
            }

            return {};
        }
    };
    const saveValues = async (itemSchema: MetadataItemSchema<MetadataLiteralTypes>) => {
        if (!initialValues) return;

        MetadataCollectionSchema.editItemSchema({ itemSchemaKey: Number(itemIndex), itemSchema, schema });

        await Data.put([schema]);
    };

    const handleBack = () => navigate(`/custom-fields/schemas/${schema._id}`);

    return (
        <Dialog open={Boolean(itemIndex)} onClose={handleBack} maxWidth="sm" fullWidth>
            <DialogTitle>{t(isNew ? 'custom-fields.item-form-create-title' : 'custom-fields.item-form-edit-title')}</DialogTitle>
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);

                    //ensure notes is a number;
                    if (values.notesRequired) {
                        values.notesRequired = Number(values.notesRequired);
                    }
                    await saveValues(values);
                    setSubmitting(false);

                    navigate(`/custom-fields/schemas/${schema._id}`);
                }}
            >
                {() => {
                    return <CustomFieldsItemSchemaEditForm onClose={handleBack} />;
                }}
            </Formik>
        </Dialog>
    );
};
