import { List, Stack } from '@mui/material';
import { SmartHeader } from '@nexdynamic/nex-ui-react';
import { useMetadataCompleteContext } from '../context/useMetadataContext';
import { CustomFieldComponent } from './fields/CustomFieldComponent';

export const CustomFieldsCompletionFieldsView = () => {
    const { selectedCollectionSchema, selectItemSchema, selectedCollectionValue, setBooleanInstanceValue, skipItem } =
        useMetadataCompleteContext();

    if (!selectedCollectionSchema) return null;

    return (
        <>
            <SmartHeader title={selectedCollectionSchema.title} />

            <List component={Stack} spacing={1}>
                {Object.keys(selectedCollectionSchema.items).map(keyAsString => {
                    const itemSchemaKey = Number(keyAsString);
                    if (isNaN(itemSchemaKey)) return null;

                    const itemSchema = selectedCollectionSchema.items[itemSchemaKey];
                    const item = selectedCollectionValue?.values[itemSchemaKey];

                    return (
                        <CustomFieldComponent
                            key={itemSchemaKey}
                            itemSchema={itemSchema}
                            item={item}
                            itemSchemaKey={itemSchemaKey}
                            setBooleanInstanceValue={setBooleanInstanceValue}
                            skipItem={skipItem}
                            selectItemSchema={selectItemSchema}
                            collectionSchema={selectedCollectionSchema}
                        />
                    );
                })}
            </List>
        </>
    );
};
