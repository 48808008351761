import type { Notification } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import useStoredObjects from '../../hooks/useStoredObjects';

export const useOutbox = () => {
    const where = useMemo(() => {
        return { status: 'pending' as const };
    }, []);
    const notifications = useStoredObjects<Notification>('notifications', undefined, where);

    return notifications;
};
