import type { AvailableMetadataTarget } from '@nexdynamic/squeegee-common';
import type React from 'react';
import { createContext, useContext, useState } from 'react';

export type GlobalCustomFieldsContextType = {
    type: AvailableMetadataTarget;
    setType: (type: AvailableMetadataTarget) => void;
    closeDialog?: () => void;
};

export const GlobalCustomFieldsContext = createContext<GlobalCustomFieldsContextType>({
    type: 'any',
    setType: () => {
        return;
    },
    closeDialog: () => {
        return;
    },
});

export const useGlobalCustomFieldsContext = () => {
    const context = useContext(GlobalCustomFieldsContext);
    if (!context) {
        throw new Error('useGlobalCustomFieldsContext must be used within a GlobalCustomFieldsContextProvider');
    }
    return context;
};

export const GlobalCustomFieldsContextProvider = ({ children, close }: { children: React.ReactNode; close: () => void }) => {
    const [type, setType] = useState<AvailableMetadataTarget>('any');

    return (
        <GlobalCustomFieldsContext.Provider
            value={{
                type,
                setType,
                closeDialog: close,
            }}
        >
            {children}
        </GlobalCustomFieldsContext.Provider>
    );
};
