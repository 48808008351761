import type { FieldSettingsNumber, FieldSettingsSelect, FieldSettingsText, MetadataLiteralTypes } from '@nexdynamic/squeegee-common';

export const getAvailableSettingsForCustomField = (
    type: MetadataLiteralTypes
): FieldSettingsNumber | FieldSettingsText | FieldSettingsSelect | undefined => {
    switch (type) {
        case 'number': {
            const numberSettings: FieldSettingsNumber = {
                decimalPlaces: 0,
                prefix: '',
                suffix: '',
            };

            return numberSettings;
        }
        case 'text': {
            const textSettings: FieldSettingsText = {
                prefix: '',
                suffix: '',
            };

            return textSettings;
        }
        case 'select': {
            const selectSettings: FieldSettingsSelect = {
                selectMultiple: false,
            };

            return selectSettings;
        }

        default:
            break;
    }
};
