import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import type { SchemaItem } from './CustomFieldDialog';
import { CustomFieldList } from './CustomFieldList';

export const PaginatedCustomFieldList = ({
    schemas,
    pageSize,
    submitSelectedSchemas,
    toggledItems,
    handleToggle,
}: {
    schemas: Map<string, SchemaItem>;
    pageSize: number;
    submitSelectedSchemas: (selectedSchemas: Map<string, SchemaItem>) => void;
    toggledItems: Set<string>;
    handleToggle: (id: string) => void;
}) => {
    const [currentPage, setCurrentPage] = useState(1);

    const next = () => setCurrentPage(previous => previous + 1);
    const previous = () => setCurrentPage(previous => Math.max(previous - 1, 1));

    const start = (currentPage - 1) * pageSize;
    const items = Array.from(schemas.values()).slice(start, start + pageSize);

    const schemasToDisplay = new Map(items.map(item => [item.schema._id, item]));
    return (
        <div>
            <CustomFieldList
                schemas={schemasToDisplay}
                submitSelectedSchemas={submitSelectedSchemas}
                toggledItems={toggledItems}
                onToggle={handleToggle}
            />
            <Stack direction={'row'} justifyContent={'right'} gap={1}>
                <Button variant="contained" onClick={previous} disabled={currentPage === 1}>
                    {ApplicationState.localise('general.back')}
                </Button>
                <Button variant="contained" onClick={next} disabled={start + pageSize >= items.length}>
                    {ApplicationState.localise('general.next')}
                </Button>
            </Stack>
        </div>
    );
};
