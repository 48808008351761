import type { AutocompleteRenderInputParams } from '@mui/material';
import { Autocomplete, Stack, TextField } from '@mui/material';
import type { ArrayMetadataTypes, MetadataItemInstance } from '@nexdynamic/squeegee-common';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { t } from '../../../../../../t';
import { FieldFormHeader } from '../../../dialog/components/FieldFormHeader';
import type { MetadataFormProps } from '../getFormForMetadata';

export const SelectMetadataItemForm = (props: MetadataFormProps<'select'>) => {
    const { itemSchema, saveValues, itemInstance, skipItem } = props;
    const [values, setValues] = useState<MetadataItemInstance<'select'>>(
        itemInstance ?? {
            value: [],
            notes: '',
        }
    );

    if (!itemSchema.settings?.selectOptions) {
        throw new Error('SelectMetadataItemForm requires selectOptions to be defined');
    }

    const handleValueChange = (field: keyof MetadataItemInstance<ArrayMetadataTypes>) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValues = {
            ...values,
            [field]: event.target.value,
        };

        setValues(newValues);
    };

    const handleAutoCompleteSelectChange =
        (field: keyof MetadataItemInstance<ArrayMetadataTypes>) => (_: SyntheticEvent, newValue: string | null) => {
            if (newValue === null) return;

            const newValues = {
                ...values,
                [field]: Array.isArray(newValue) ? newValue : [newValue],
            };

            setValues(newValues);
        };

    return (
        <Stack spacing={2}>
            <FieldFormHeader
                itemSchema={itemSchema}
                itemInstance={itemInstance}
                values={values}
                setValues={setValues}
                skipItem={skipItem}
                saveValues={saveValues}
            />

            <Autocomplete
                value={values.value}
                multiple={!!itemSchema.settings.selectMultiple}
                disableCloseOnSelect={!!itemSchema.settings.selectMultiple}
                options={itemSchema.settings?.selectOptions ?? []}
                onChange={handleAutoCompleteSelectChange('value')}
                renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} label="Select options" fullWidth />}
            />

            <TextField
                label={t('general.notes')}
                value={values.notes}
                onChange={handleValueChange('notes')}
                multiline
                rows={4}
                inputProps={{ maxLength: 255 }}
            />
        </Stack>
    );
};
