import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import { Chip, ListItem, ListItemButton, ListItemText, Paper } from '@mui/material';
import type { MetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router-dom';
import { ApplicationState } from '../../../ApplicationState';
import { t } from '../../../t';

export const CollectionSchemaItem = ({ schema }: { schema: MetadataCollectionSchema }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/custom-fields/schemas/${schema._id}`);
    };

    const count = Object.keys(schema.items).length;
    const fieldDescription =
        count === 1 ? t('custom-fields.collection-list-field', { count }) : t('custom-fields.collection-list-fields', { count });

    return (
        <ListItem component={Paper} disablePadding secondaryAction={<ChevronRightIcon />}>
            <ListItemButton onClick={handleClick} sx={{ minHeight: 64 }}>
                <ListItemText
                    primary={schema.title + ' ' + fieldDescription}
                    secondary={schema.description}
                    secondaryTypographyProps={{
                        sx: { textWrap: 'nowrap', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' },
                    }}
                />

                <Chip key={schema.target} label={ApplicationState.localise(`custom-fields.target-${schema.target}`)} size="small" />
            </ListItemButton>
        </ListItem>
    );
};
