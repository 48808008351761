import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import SearchIcon from '@mui/icons-material/SearchRounded';
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { SkeletonList } from '@nexdynamic/nex-ui-react';
import type { ChangeEvent } from 'react';
import { useMemo, useState } from 'react';
import { GlobalFlags } from '../../../../GlobalFlags';
import { Api } from '../../../../Server/Api';
import { openSystemBrowser } from '../../../../Utilities';
import ActionableListItem from '../../../email-settings/components/ActionableListItem';
import useTranslation from '../../../hooks/useTranslation';
import { useGcBankData } from '../providers/GcBankDataProvider';
import { SandboxInstitution } from './SandboxInstitution';

type Props = {
    limit?: number;
    disableSearch?: boolean;
};

export const GcBankDataInstitutionsList: React.FC<Props> = ({ limit, disableSearch }) => {
    const { t } = useTranslation();
    const { loading, institutions } = useGcBankData();

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [redirecting, setRedirecting] = useState<boolean>(false);

    const filteredInstitutions = useMemo(
        () => institutions.filter(institution => institution.name.toLowerCase().includes(searchQuery.toLowerCase())),
        [institutions, searchQuery]
    );

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const onSelectInstitution = async (institutionId: string) => {
        setRedirecting(true);
        const response = await Api.post<{ url: string }>(null, '/api/banking-data/sessions', {
            institutionId,
        });

        if (response?.status !== 200) {
            console.error('Failed to create bank session', response);
            return;
        }

        const url = response.data.url;

        if (GlobalFlags.isMobileApp) openSystemBrowser(url);
        else window.open(url, '_parent');
    };

    return (
        <Stack gap={1}>
            {!disableSearch && (
                <TextField
                    placeholder={t('connected-services.search-banks')}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: searchQuery && (
                            <InputAdornment position="end" onClick={() => setSearchQuery('')}>
                                <IconButton size="small">
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    value={searchQuery}
                    onChange={handleSearch}
                    sx={{ mb: 1 }}
                />
            )}
            <SandboxInstitution onSelect={onSelectInstitution} />

            {loading && !institutions.length && <SkeletonList itemHeight={60} />}

            {filteredInstitutions.map(institution => (
                <ActionableListItem
                    key={institution.id}
                    primary={institution.name}
                    slots={{
                        avatar: (
                            <img width={40} height={40} src={institution.logo} alt={institution.name} style={{ borderRadius: '4px' }} />
                        ),
                        secondaryAction: <ChevronRightIcon />,
                    }}
                    onClick={() => onSelectInstitution(institution.id)}
                    disabled={redirecting}
                />
            ))}

            {institutions.length > 0 &&
                (!filteredInstitutions.length ? (
                    <Typography variant="body2" color="text.secondary" textAlign="center">
                        {t('connected-services.search-no-results', { searchQuery })}
                    </Typography>
                ) : (
                    <>
                        {!searchQuery && limit && institutions.length > limit && (
                            <Typography variant="body2" color="text.secondary" textAlign="center">
                                {t('connected-services.showing-x-results', {
                                    limit: limit.toString(),
                                    total: institutions.length.toString(),
                                })}
                            </Typography>
                        )}
                    </>
                ))}

            {!loading && !institutions.length && (
                <Typography variant="body2" textAlign="center">
                    {t('connected-services.institutions-unavailable')}
                </Typography>
            )}
        </Stack>
    );
};
