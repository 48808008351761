import { Button, DialogActions, DialogContent, FormControlLabel, MenuItem, Stack } from '@mui/material';
import type { ZodMetadataItemSchema } from '@nexdynamic/squeegee-common';
import { NotesRequirement, NotesRequirementLabels, metadataTypeValues } from '@nexdynamic/squeegee-common';
import { Field, Form, useFormikContext } from 'formik';
import { Select, Switch, TextField } from 'formik-mui';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { prompt } from '../../../Dialogs/ReactDialogProvider';
import { isDevMode } from '../../../isDevMode';
import { t } from '../../../t';
import { MetadataCollectionService } from '../MetadataCollectionService';
import { AddSelectOptionsField } from './AddSelectOptionsField';
import { CustomFieldSettingsComponent } from './Settings/CustomFieldSettingsComponent';

export const CustomFieldsItemSchemaEditForm = ({ onClose }: { onClose: () => void }) => {
    const { initialValues, values } = useFormikContext<ZodMetadataItemSchema>();
    const navigate = useNavigate();

    const { schemaId, itemIndex } = useParams();
    const itemIndexToUse = itemIndex !== undefined ? Number(itemIndex) : undefined;
    const isNew = useMemo(() => new URLSearchParams(window.location.search).get('new') === 'true', []);

    if (initialValues) {
        if (!metadataTypeValues.includes(initialValues.type as (typeof metadataTypeValues)[number])) {
            initialValues.type = metadataTypeValues[0];
        }

        if (!Object.keys(NotesRequirementLabels).map(Number).includes(Number(initialValues.notesRequired))) {
            initialValues.notesRequired = NotesRequirement.Optional;
        }
    }
    const notesRequirementTypes = Object.entries(NotesRequirementLabels)
        .filter(([value]) => Number(value) !== NotesRequirement.WhenSkipped || values['skippable'])
        .map(([value, text]) => {
            if (Number(value) === NotesRequirement.WhenSkippedOrFalsy && !values['skippable']) {
                text = 'custom-fields.notes-required-when-falsy';
            }
            return [Number(value), t(text)];
        });

    const deleteItem = async () => {
        if (!schemaId || itemIndexToUse === undefined) return;

        const confirmDelete = prompt('general.delete', 'custom-fields.delete-item-confirmation', {
            okLabel: 'general.delete',
            cancelLabel: 'general.cancel',
        });

        if (!(await confirmDelete.show())) return;

        await MetadataCollectionService.deleteSchemaItem(schemaId, Number(itemIndexToUse));
        navigate(`/custom-fields/schemas/${schemaId}`);
    };

    const isFieldInUse =
        schemaId && itemIndexToUse !== undefined ? MetadataCollectionService.isSchemaFieldInUse(schemaId, itemIndexToUse) : false;
    const canEditType = isNew || !isFieldInUse || isDevMode();

    return (
        <Form>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2, pt: 1 }}>
                <Field
                    component={Select}
                    name="type"
                    label={t('custom-fields.item-type-field')}
                    disabled={!canEditType}
                    readOnly={!canEditType}
                >
                    {metadataTypeValues.map(type => (
                        <MenuItem key={type} value={type}>
                            {t(`custom-fields.type-${type}`)}
                        </MenuItem>
                    ))}
                </Field>
                {values.type === 'select' ? (
                    <Field
                        component={AddSelectOptionsField}
                        name="settings.selectOptions"
                        label={t('custom-fields.item-select-options')}
                        disabled={!canEditType}
                        readOnly={!canEditType}
                    />
                ) : null}
                <Field component={TextField} name="title" label={t('custom-fields.item-name-field')} fullWidth />
                <Field
                    component={TextField}
                    name="description"
                    label={t('custom-fields.item-instructions-field')}
                    rows={3}
                    multiline
                    fullWidth
                />
                <Field component={Select} name="notesRequired" label={t('custom-fields.item-notes-required-field')}>
                    {notesRequirementTypes.map(([value, text]) => (
                        <MenuItem key={value} value={value}>
                            {text}
                        </MenuItem>
                    ))}
                </Field>
                <Stack px={1}>
                    <FormControlLabel
                        control={<Field component={Switch} name="skippable" type="checkbox" />}
                        label={t('custom-fields.item-skippable-field')}
                    />
                    <FormControlLabel
                        control={<Field component={Switch} name="active" type="checkbox" />}
                        label={t('custom-fields.field-is-active')}
                    />
                </Stack>

                {values.type && <CustomFieldSettingsComponent />}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>{t('general.cancel')}</Button>
                {!isNew && !isFieldInUse && (
                    <Button variant="contained" color="error" type="button" onClick={deleteItem}>
                        {t('general.delete')}
                    </Button>
                )}
                <Button variant="contained" type="submit">
                    {t('general.save')}
                </Button>
            </DialogActions>
        </Form>
    );
};
