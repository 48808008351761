import AddIcon from '@mui/icons-material/AddRounded';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TemplateIcon from '@mui/icons-material/FileCopy';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import { Box, Breadcrumbs, Button, Container, Fade, IconButton, Link, Stack } from '@mui/material';
import { ActionMenu, FormDialog, useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import type { YupMetadataCollectionSchema, ZodMetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { MetadataCollectionSchema, yupMetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Data } from '../../../../Data/Data';
import { t } from '../../../../t';
import Text from '../../../components/Text';
import { MetadataCollectionList } from '../../Components/MetadataCollectionList';
import { MetadataCollectionForm } from '../../CreateMetadata/Forms/MetadataCollectionForm';
import { MetadataCollectionService } from '../../MetadataCollectionService';
import { DisplayTemplateOptions } from '../../Templates/DisplayTemplateOptions';
import { customFieldTemplates } from '../../Templates/collectionSchemaTemplates';
export const CustomFieldsMain = () => {
    const [view, setView] = useState<'schema' | 'template'>('schema');
    const [showForm, setShowForm] = useState<'schema' | 'template' | false>(false);
    const [customTemplates, setCustomTemplates] = useState<Array<ZodMetadataCollectionSchema> | null>(null);
    const [standardTemplates, setStandardTemplates] = useState<Array<ZodMetadataCollectionSchema> | null>(null);
    const navigate = useNavigate();

    const onSubmitForm = async (values: YupMetadataCollectionSchema) => {
        setShowForm(false);
        const target = values.target || undefined;

        const schema = new MetadataCollectionSchema(values.title, {}, values.description, target, values.isTemplate);
        await Data.put(schema);

        navigate(`/custom-fields/schemas/${schema._id}`);
    };

    const handleClose = () => {
        setShowForm(false);
    };
    const fullScreen = useIsSmallScreen();

    const selectTemplate = () => {
        const customTemplates = MetadataCollectionService.getTemplates();
        const standardTemplates = customFieldTemplates.map(
            template =>
                new MetadataCollectionSchema(
                    template.title,
                    template.items || {},
                    template.description,
                    template.target,
                    template.isTemplate
                )
        );
        if (!(customTemplates || standardTemplates) || (customTemplates.length === 0 && standardTemplates.length === 0)) {
            enqueueSnackbar(t('custom-fields.schema-create-template-error'), { variant: 'warning' });
            return;
        }

        setStandardTemplates(standardTemplates);
        setCustomTemplates(customTemplates);
    };

    const closeTemplatesDialog = () => {
        setCustomTemplates(null);
        setStandardTemplates(null);
    };

    const toggleShowTemplates = () => {
        setView(view === 'schema' ? 'template' : 'schema');
        console.log('view', view);
    };
    const showTemplateSelectionDialog = customTemplates !== null || standardTemplates !== null;
    return (
        <Fade in>
            <Container maxWidth="xl" sx={{ my: 2 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    {view === 'schema' ? (
                        <Text text="custom-fields.schema-title" />
                    ) : (
                        <Box display="flex" alignItems="center" gap={1}>
                            <IconButton onClick={() => toggleShowTemplates()}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Breadcrumbs>
                                <Link onClick={() => toggleShowTemplates()} underline="hover" sx={{ cursor: 'pointer' }}>
                                    {t('custom-fields.schema-title')}
                                </Link>
                                <Text text="custom-fields.template-title" />
                            </Breadcrumbs>
                        </Box>
                    )}
                    <Stack direction="row" spacing={1}>
                        {view === 'schema' ? (
                            <Stack direction="row" spacing={1}>
                                <Button variant="contained" onClick={() => setShowForm('schema')} startIcon={<AddIcon />}>
                                    {t('custom-fields.schema-create-button')}
                                </Button>
                                <Button variant="outlined" onClick={() => selectTemplate()} startIcon={<TemplateIcon />}>
                                    {t('custom-fields.schema-create-from-template-button')}
                                </Button>
                            </Stack>
                        ) : (
                            <Button variant="text" onClick={() => setShowForm('template')} startIcon={<FolderCopyIcon />}>
                                {t('custom-fields.create-template')}
                            </Button>
                        )}

                        <ActionMenu
                            actions={
                                view === 'schema'
                                    ? [
                                          {
                                              label: t('custom-fields.toggle-templates'),
                                              onClick: () => toggleShowTemplates(),
                                              icon: <AltRouteIcon />,
                                          },
                                          {
                                              label: t('custom-fields.create-template'),
                                              onClick: () => setShowForm('template'),
                                              icon: <FolderCopyIcon />,
                                          },
                                      ]
                                    : [
                                          {
                                              label: t('custom-fields.toggle-templates'),
                                              onClick: () => toggleShowTemplates(),
                                              icon: <AltRouteIcon />,
                                          },
                                          {
                                              label: t('custom-fields.schema-create-button'),
                                              onClick: () => setShowForm('schema'),
                                              icon: <AddIcon />,
                                          },
                                          {
                                              label: t('custom-fields.schema-create-from-template-button'),
                                              onClick: () => selectTemplate(),
                                              icon: <TemplateIcon />,
                                          },
                                      ]
                            }
                        />
                    </Stack>
                </Stack>

                <MetadataCollectionList view={view} />

                {showForm && (
                    <FormDialog
                        initialValues={new MetadataCollectionSchema('', {}, '', 'any')}
                        schema={yupMetadataCollectionSchema}
                        open={true}
                        onClose={handleClose}
                        title={t('custom-fields.create-collection-form-title')}
                        discardText={t('general.cancel')}
                        onSave={async values => {
                            await onSubmitForm({
                                description: values.description,
                                items: {},
                                title: values.title,
                                target: values.target,
                                isTemplate: showForm === 'template' ? true : false,
                            });

                            enqueueSnackbar(t('custom-fields.schema-create-success'), { variant: 'success' });
                        }}
                        fullScreen={fullScreen}
                    >
                        <MetadataCollectionForm />
                    </FormDialog>
                )}
                {showTemplateSelectionDialog && (
                    <DisplayTemplateOptions
                        closeTemplatesDialog={closeTemplatesDialog}
                        customTemplates={customTemplates}
                        standardTemplates={standardTemplates}
                    />
                )}
            </Container>
        </Fade>
    );
};
