import type { MetadataCollectionSchema, MetadataItemInstance, MetadataItemSchema, MetadataLiteralTypes } from '@nexdynamic/squeegee-common';
import { CustomFieldListItem } from '../components/CustomFieldListItem';
import { CompletedCheckboxField } from '../item-forms/completed-forms/CompletedCheckboxField';
import { isBooleanMetadataSchema } from '../item-forms/completed-forms/completed-form-helpers/isBooleanMetadataSchema';
import { resolveCompletedCustomFields } from '../item-forms/completed-forms/completed-form-helpers/resolveCompletedCustomFields';

export type CustomFieldComponentProps<T extends MetadataLiteralTypes> = {
    item?: MetadataItemInstance<T>;
    itemSchema: MetadataItemSchema<T>;
    itemSchemaKey: number;
    selectItemSchema: (key: number | null) => void;
    setBooleanInstanceValue: (itemSchemaKey: number, value: boolean | undefined) => void;
    skipItem: (itemSchemaKey: number) => void;
    collectionSchema: MetadataCollectionSchema;
};

export const CustomFieldComponent = ({
    item,
    itemSchema,
    itemSchemaKey,
    setBooleanInstanceValue,
    skipItem,
    selectItemSchema,
    collectionSchema,
}: CustomFieldComponentProps<MetadataLiteralTypes>) => {
    if (!itemSchema.active && !item) return null;

    if (item) {
        return resolveCompletedCustomFields({
            item: item,
            itemSchemaKey,
            setBooleanInstanceValue,
            skipItem,
            selectItemSchema,
            collectionSchema,
        });
    }

    if (isBooleanMetadataSchema(itemSchema)) {
        return (
            <CompletedCheckboxField
                key={itemSchemaKey}
                item={item || {}}
                selectItemSchema={selectItemSchema}
                itemSchema={itemSchema}
                itemSchemaKey={itemSchemaKey}
                setBooleanInstanceValue={setBooleanInstanceValue}
                skipItem={skipItem}
            />
        );
    }

    // Return the generic list item for non-specialised fields
    return <CustomFieldListItem key={itemSchemaKey} item={item || {}} itemSchema={itemSchema} itemSchemaKey={itemSchemaKey} />;
};
