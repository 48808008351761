import type { AvailableMetadataTarget, Customer, Job, MetadataCollectionSchema, StoredObject } from '@nexdynamic/squeegee-common';
import { useEffect, useMemo, useState } from 'react';
import { Data } from '../../../Data/Data';
import type { AureliaReactComponentDialogComponentStandardProps } from '../../../Dialogs/AureliaReactComponentDialogComponent';
import { NotifyUserMessage } from '../../../Notifications/NotifyUserMessage';
import { t } from '../../../t';
import { MetadataCollectionService } from '../MetadataCollectionService';
import { CustomFieldsDialogFilters } from './CustomFieldsDialogFiltersComponent';
import { GlobalSchemaList } from './GlobalSchemaList';
import type { CustomFieldsDialogProps } from './HelperFunctions/addCustomFields';
import { buildCustomFieldSchemaStructure } from './HelperFunctions/buildCustomFieldSchemaStructure';
import { PaginatedCustomFieldList } from './PaginatedCustomFieldList';
import type { SimpleTabsType } from './Tabs/SimpleTab';
import { SimpleTab } from './Tabs/SimpleTab';

export type SchemaItem = {
    schema: MetadataCollectionSchema;
    isEnabled: boolean;
};

export type CustomFieldDataStructure = Map<string, SchemaItem>;

export type CustomFieldsDialogFiltersType = { search: string; filterTarget?: AvailableMetadataTarget };

type Props = AureliaReactComponentDialogComponentStandardProps<void> & CustomFieldsDialogProps;

export const CustomFieldDialog = ({ relatedObjectId, type, customerId, filterTarget, okCallback }: Props) => {
    const { schemaDataStructure, global } = useMemo(
        () =>
            buildCustomFieldSchemaStructure({
                relatedObjectId,
                type,
                customerId,
            }),
        [relatedObjectId, type, customerId]
    );
    const [filters, setFilters] = useState<CustomFieldsDialogFiltersType>({ search: '', filterTarget: filterTarget });
    const [filteredSchemas, setFilteredSchemas] = useState<CustomFieldDataStructure>(new Map());

    const tItems = Array.from(schemaDataStructure.values())
        .filter(schemaAndIfEnabled => schemaAndIfEnabled.isEnabled)
        .map(schemaAndIfEnabled => schemaAndIfEnabled.schema._id);

    const [toggledItems, setToggledItems] = useState<Set<string>>(new Set(tItems));

    const handleToggle = (id: string) => {
        setToggledItems(prev => {
            const newToggledItems = new Set(prev);
            if (newToggledItems.has(id)) {
                newToggledItems.delete(id);
            } else {
                newToggledItems.add(id);
            }
            return newToggledItems;
        });
    };

    useEffect(() => {
        const search = filters.search.trim().toLowerCase();
        if (!filters.filterTarget) setFilteredSchemas(schemaDataStructure);
        if (search === '') setFilteredSchemas(schemaDataStructure);

        const filtered = new Map(
            [...schemaDataStructure].filter(([, value]) => {
                const targetMatch = value.schema.target === filterTarget || value.schema.target === 'any';

                const searchMatch =
                    value.schema.title.trim().toLowerCase().includes(search) ||
                    value.schema.description?.trim().toLowerCase().includes(search);

                return targetMatch && searchMatch;
            })
        );

        setFilteredSchemas(filtered);
    }, [filters.search, filters.filterTarget]);

    const handleSubmit = async (schemas: Map<string, SchemaItem>) => {
        const schemaIds = Array.from(toggledItems);

        let object: StoredObject | Job | undefined;
        if (customerId) {
            const customer = Data.get<Customer>(customerId);
            if (!customer) {
                new NotifyUserMessage('error.unknown-custom-field-object-not-found');
                return;
            }

            object = customer.jobs[relatedObjectId];
        } else {
            object = Data.get<StoredObject>(relatedObjectId);
        }

        if (!object) {
            new NotifyUserMessage('error.unknown-custom-field-object-not-found');
            return;
        }

        for (const schemaId of schemaIds) {
            const schema = Data.get<MetadataCollectionSchema>(schemaId);
            if (!schema) continue;
            await MetadataCollectionService.linkMetadataCollectionSchema(schema, object);
        }

        for (const schema of schemas.values()) {
            if (!toggledItems.has(schema.schema._id)) {
                await MetadataCollectionService.remove(object._id, schema.schema);
            }
        }

        okCallback();
    };

    const tabs: SimpleTabsType = [
        {
            tab: 0,
            label: t('general.custom-fields'),
            content: (
                <PaginatedCustomFieldList
                    schemas={filteredSchemas}
                    submitSelectedSchemas={schemas => handleSubmit(schemas)}
                    pageSize={10}
                    toggledItems={toggledItems}
                    handleToggle={handleToggle}
                />
            ),
        },
        {
            tab: 1,
            label: t('custom-fields.global-fields'),
            content: <GlobalSchemaList schemas={global} />,
        },
    ];

    const onTabChange = () => {
        setFilters({ filterTarget: filterTarget, search: '' });
    };

    return (
        <div>
            <CustomFieldsDialogFilters setFilters={setFilters} filters={filters} />
            <SimpleTab tabs={tabs} detectTabChange={onTabChange} />
        </div>
    );
};
