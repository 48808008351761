import { Container } from '@mui/material';
import { DialogRoot, ViewRenderer, ViewRouter, createViewRouter, useViewRouter } from '@nexdynamic/nex-ui-react';
import type { Job, MetadataCollectionSchema, StoredObject } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { AureliaReactComponentDialogComponentStandardProps } from '../../../../Dialogs/AureliaReactComponentDialogComponent';
import AppProviders from '../../../AppProviders';
import ApplicationStateProvider from '../../../components/ApplicationStateProvider';
import { BrowserFallBackToHashRouter } from '../../../components/BrowserFallBackToHashRouter';
import UncaughtErrorFallback from '../../../components/UncaughtErrorFallback';
import { MetadataCompleteContextProvider } from '../context/useMetadataContext';
import { CustomFieldsCompletionFieldsView } from '../forms/CustomFieldsSchemaCompletionList';
import { ItemSchemaForm } from '../forms/item-forms/ItemSchemaForm';
import { CustomFieldsCompletionCollectionView } from './components/CustomFieldsCompletionComponent';

type MetadataCompleteViewTypes = 'collection-schema-list' | 'item-schema-list' | 'item-schema-form';
export const useMetadataCompleteRouter = () => useViewRouter<MetadataCompleteViewTypes>();

export type MetadataCompleteDialogProps = {
    targetObject: StoredObject | Job;
    schemasToComplete?: Array<MetadataCollectionSchema>;
    completeAction?: () => void;
};

type Props = AureliaReactComponentDialogComponentStandardProps<boolean> & MetadataCompleteDialogProps;

export const CustomFieldsCompletionDialog = ({ targetObject, schemasToComplete }: Props) => {
    const metadataCompleteViews = {
        'collection-schema-list': () => <CustomFieldsCompletionCollectionView />,
        'item-schema-list': () => <CustomFieldsCompletionFieldsView />,
        'item-schema-form': () => <ItemSchemaForm />,
    } as const;

    const viewRoutes = useMemo(() => {
        return createViewRouter<MetadataCompleteViewTypes>(metadataCompleteViews);
    }, [targetObject]);

    const initialView = schemasToComplete?.length === 1 ? 'item-schema-list' : 'collection-schema-list';

    return (
        <ApplicationStateProvider>
            <BrowserFallBackToHashRouter>
                <AppProviders>
                    <ErrorBoundary fallback={<UncaughtErrorFallback />}>
                        <ViewRouter initialView={initialView}>
                            <MetadataCompleteContextProvider
                                key={targetObject._id}
                                relatedObject={targetObject}
                                schemasToComplete={schemasToComplete}
                            >
                                <Container maxWidth="lg" disableGutters>
                                    <DialogRoot>
                                        <ViewRenderer views={viewRoutes} />
                                    </DialogRoot>
                                </Container>
                            </MetadataCompleteContextProvider>
                        </ViewRouter>
                    </ErrorBoundary>
                </AppProviders>
            </BrowserFallBackToHashRouter>
        </ApplicationStateProvider>
    );
};
