import { Stack } from '@mui/material';
import { GradientButton } from '@nexdynamic/nex-ui-react';
import type { MetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { validateMetadataCollectionSchema } from '@nexdynamic/squeegee-common';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Data } from '../../../Data/Data';
import { t } from '../../../t';
import { MetadataCollectionForm } from '../CreateMetadata/Forms/MetadataCollectionForm';
export const MetadataCollectionSchemaEditForm = ({ schemaToEdit }: { schemaToEdit: MetadataCollectionSchema }) => {
    const navigate = useNavigate();
    return (
        <Formik
            initialValues={schemaToEdit}
            validate={validateMetadataCollectionSchema}
            onSubmit={async values => {
                await Data.put<MetadataCollectionSchema>({
                    ...values,
                });
                navigate('/custom-fields/schemas/' + schemaToEdit._id);
            }}
        >
            <Form title={t('custom-fields.editing-schema')}>
                {<MetadataCollectionForm editing={true} />}
                <Stack spacing={2} direction="row" justifyContent="space-between" my={1}>
                    <GradientButton
                        variant="contained"
                        text={t('general.cancel')}
                        onClick={() => navigate('/custom-fields/schemas/' + schemaToEdit._id)}
                    />
                    <GradientButton variant="contained" type="submit" text={t('general.submit')} />
                </Stack>
            </Form>
        </Formik>
    );
};
