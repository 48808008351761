import { Container } from '@mui/material';
import { DialogRoot, ViewRenderer, ViewRouter, createViewRouter } from '@nexdynamic/nex-ui-react';
import { useEffect, useMemo } from 'react';
import { prompt } from '../../Dialogs/ReactDialogProvider';
import type { EmailSettingsViewRoutes } from './EmailSettingsViewRoutes';
import { EmailEngineProvider } from './provider/EmailEngineProvider';
import { MailServerProvider } from './provider/MailServerProvider';
import { Blocklist } from './views/Blocklist';
import CustomEmailSettings from './views/CustomEmailSettings';
import { EmailSettingsMain } from './views/EmailSettingsMain';
import { GmailSettings } from './views/GmailSettings';
import { MailServerConfig } from './views/MailServerConfig';
import MailServerSetupWizard from './views/MailServerWizard/MailServerSetupWizard';
import { MicrosoftSettings } from './views/MicrosoftSettings';
import OneWaySettings from './views/OneWaySettings';

export type EmailSettingsDialogView = 'root' | 'provider' | 'blocklist';
export const EmailSettingsDialog = () => {
    const viewRoutes = useMemo(() => {
        return createViewRouter<EmailSettingsViewRoutes>({
            'email-settings': () => <EmailSettingsMain />,
            'one-way': () => <OneWaySettings />,
            'custom-provider': () => <CustomEmailSettings />,
            'custom-provider/setup-wizard': () => <MailServerSetupWizard />,
            'custom-provider/smtp': () => <MailServerConfig parentRoot="custom-provider" protocol="smtp" />,
            'custom-provider/imap': () => <MailServerConfig parentRoot="custom-provider" protocol="imap" />,
            gmail: () => <GmailSettings />,
            microsoft: () => <MicrosoftSettings />,
            blocklist: () => <Blocklist />,
        } as const);
    }, []);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const connectFailed = query.get('connect-failed') === 'true';

        if (connectFailed) {
            query.delete('connect-failed');
            const newQuery = query.toString();
            const newUrl = window.location.pathname + (newQuery ? `?${newQuery}` : newQuery);
            window.history.replaceState({}, '', newUrl);
        }

        if (!connectFailed) return;

        prompt('email-settings.failed-to-connect-provider-email-title', 'email-settings.failed-to-connect-provider-email-description', {
            cancelLabel: '',
        }).show();
    }, []);

    return (
        <ViewRouter initialView="email-settings">
            <EmailEngineProvider>
                <MailServerProvider>
                    <Container maxWidth="lg" disableGutters>
                        <DialogRoot>
                            <ViewRenderer views={viewRoutes} />
                        </DialogRoot>
                    </Container>
                </MailServerProvider>
            </EmailEngineProvider>
        </ViewRouter>
    );
};
