import type { MetadataCollectionSchema, MetadataItemInstance, MetadataLiteralTypes } from '@nexdynamic/squeegee-common';
import { CustomFieldListItem } from '../../../components/CustomFieldListItem';
import { CompletedAttachmentField } from '../CompletedAttachmentField';
import { CompletedCheckboxField } from '../CompletedCheckboxField';
import { CompletedSelectField } from '../CompletedSelectField';
import { CompletedSignatureField } from '../CompletedSignatureField';
import { isAttachmentMetadataSchema } from './isAttachmentMetadataSchema';
import { isBooleanMetadataInstance } from './isBooleanMetadataInstance';
import { isBooleanMetadataSchema } from './isBooleanMetadataSchema';
import { isSelectMetadataInstance } from './isSelectMetadataInstance';
import { isSelectMetadataSchema } from './isSelectMetadataSchema';
import { isSignatureMetadataSchema } from './isSignatureMetadataSchema';
import { isStringMetadataInstance } from './isStringMetadataInstance';

type ResolveCompletedCustomFieldsProps<T extends MetadataLiteralTypes> = {
    item: MetadataItemInstance<T>;
    itemSchemaKey: number;
    setBooleanInstanceValue: (itemSchemaKey: number, value: boolean | undefined) => void;
    skipItem: (itemSchemaKey: number) => void;
    selectItemSchema: (key: number | null) => void;
    collectionSchema: MetadataCollectionSchema;
};

export const resolveCompletedCustomFields = <T extends MetadataLiteralTypes>({
    item,
    itemSchemaKey,
    selectItemSchema,
    setBooleanInstanceValue,
    skipItem,
    collectionSchema,
}: ResolveCompletedCustomFieldsProps<T>) => {
    const itemSchema = collectionSchema.items[itemSchemaKey];

    if (isSignatureMetadataSchema(itemSchema) && isStringMetadataInstance(item)) {
        return (
            <CompletedSignatureField
                key={itemSchemaKey}
                item={item}
                itemSchema={itemSchema}
                selectItemSchema={selectItemSchema}
                itemSchemaKey={itemSchemaKey}
                skipItem={skipItem}
            />
        );
    }

    if (isAttachmentMetadataSchema(itemSchema) && isStringMetadataInstance(item)) {
        return (
            <CompletedAttachmentField
                key={itemSchemaKey}
                item={item}
                itemSchema={itemSchema}
                selectItemSchema={selectItemSchema}
                itemSchemaKey={itemSchemaKey}
                skipItem={skipItem}
            />
        );
    }

    if (isBooleanMetadataSchema(itemSchema) && isBooleanMetadataInstance(item)) {
        return (
            <CompletedCheckboxField
                key={itemSchemaKey}
                item={item}
                itemSchema={itemSchema}
                selectItemSchema={selectItemSchema}
                itemSchemaKey={itemSchemaKey}
                setBooleanInstanceValue={setBooleanInstanceValue}
                skipItem={skipItem}
            />
        );
    }

    if (isSelectMetadataSchema(itemSchema) && isSelectMetadataInstance(item)) {
        console.log('isArrayMetadataSchema');
        return (
            <CompletedSelectField
                key={itemSchemaKey}
                item={item || {}}
                itemSchema={itemSchema}
                itemSchemaKey={itemSchemaKey}
                selectItemSchema={selectItemSchema}
            />
        );
    }

    return <CustomFieldListItem key={itemSchemaKey} item={item} itemSchema={itemSchema} itemSchemaKey={itemSchemaKey} />;
};
