import AddIcon from '@mui/icons-material/AddRounded';
import ArchiveIcon from '@mui/icons-material/ArchiveRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Breadcrumbs, Button, Container, Fade, IconButton, Link, Stack } from '@mui/material';
import { ActionMenu } from '@nexdynamic/nex-ui-react';
import { MetadataCollectionSchema, type TranslationKey } from '@nexdynamic/squeegee-common';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Data } from '../../../Data/Data';
import { prompt } from '../../../Dialogs/ReactDialogProvider';
import Text from '../../components/Text';
import useStoredObject from '../../hooks/useStoredObject';
import useTranslation from '../../hooks/useTranslation';
import { MetadataItemList } from '../Components/MetadataItemList';
import { CustomFieldsItemSchemaEditDialog } from '../EditMetadata/CustomFieldsItemSchemaEditDialog';
import { MetadataCollectionSchemaEditDialog } from '../EditMetadata/MetadataCollectionSchemaEditDialog';
import { MetadataCollectionService } from '../MetadataCollectionService';
import { CustomFieldsCollectionSearchPopover } from './CustomFieldsCollectionSearchPopover';
import { ItemSchemaCloneDialog } from './ItemSchemaCloneDialog';

export const MetadataCollectionSchemaView = () => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [collectionSchemaForCloning, setCollectionSchemaForCloning] = useState<MetadataCollectionSchema | null>(null);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { schemaId } = useParams();

    const schema = useStoredObject<MetadataCollectionSchema>(schemaId);

    if (!schema) {
        enqueueSnackbar(t('custom-fields.schema-not-found'), { variant: 'error' });
        navigate(-1);
        return null;
    }

    // Navigate to the edit page when creating a new item
    const createNewItem = () => {
        const index = MetadataCollectionSchema.getNextIndexId(schema);
        navigate(`/custom-fields/schemas/items/edit/${schema._id}/${index}?new=true`);
    };

    const editSchema = () => navigate(`/custom-fields/schemas/${schema._id}?editing=true`);

    const inUse = MetadataCollectionService.isSchemaInUse(schema._id);

    const archiveOrDeleteCustomFieldCollection = async () => {
        const confirmDelete = prompt(
            inUse ? 'general.archive' : 'general.delete',
            inUse ? 'custom-fields.archive-schema-confirmation' : 'custom-fields.delete-schema-confirmation',
            {
                okLabel: inUse ? 'general.archive' : 'general.delete',
                cancelLabel: 'general.cancel',
            }
        );

        if (!(await confirmDelete.show())) return;

        const latestSchema = Data.get<MetadataCollectionSchema>(schema._id);
        if (!latestSchema)
            return enqueueSnackbar(t('custom-fields.archive-schema-fail', { schemaTitle: schema.title }), { variant: 'error' });

        latestSchema._archived = true;
        latestSchema._deleted = true;
        await Data.put<MetadataCollectionSchema>(latestSchema);
        enqueueSnackbar(t('custom-fields.archive-schema-success', { schemaTitle: schema.title }), { variant: 'info' });
        navigate('/custom-fields/main');
    };

    return (
        <Fade in={true}>
            <Container maxWidth="xl" sx={{ my: 2 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <IconButton onClick={() => navigate('/custom-fields/main')}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Breadcrumbs>
                            <Link onClick={() => navigate('/custom-fields/main')} underline="hover" sx={{ cursor: 'pointer' }}>
                                {t('custom-fields.schema-title')}
                            </Link>
                            <Text color="text.primary" text={schema.title as TranslationKey} />
                        </Breadcrumbs>
                    </Box>
                    <ActionMenu
                        actions={[
                            {
                                label: t('custom-fields.item-schema-create-button'),
                                onClick: createNewItem,
                                icon: <AddIcon />,
                            },
                            {
                                label: t('general.edit'),
                                onClick: editSchema,
                                icon: <EditIcon />,
                            },
                            {
                                label: t(inUse ? 'general.archive' : 'general.delete'),
                                onClick: () => archiveOrDeleteCustomFieldCollection(),
                                icon: inUse ? <ArchiveIcon /> : <DeleteIcon />,
                                menuItemProps: {
                                    color: 'error',
                                    sx: { color: 'error.main' },
                                },
                            },
                        ]}
                    />
                </Stack>

                <MetadataItemList schema={schema} />

                <Box display="flex" justifyContent="center" mt={1}>
                    <Button onClick={() => createNewItem()} startIcon={<AddIcon />} size="large" color="inherit">
                        {t('custom-fields.item-schema-create-button')}
                    </Button>
                    <Button
                        onClick={() => {
                            setPopoverOpen(true);
                        }}
                        startIcon={<AddIcon />}
                        size="large"
                        color="inherit"
                    >
                        {t('custom-fields.item-schema-clone-button')}
                    </Button>
                </Box>

                <CustomFieldsItemSchemaEditDialog schema={schema} />
                <MetadataCollectionSchemaEditDialog />
                {popoverOpen && (
                    <CustomFieldsCollectionSearchPopover
                        setPopoverOpen={setPopoverOpen}
                        viewingSchemaId={schema._id}
                        setCollectionSchemaForCloning={setCollectionSchemaForCloning}
                    />
                )}
                {collectionSchemaForCloning && (
                    <ItemSchemaCloneDialog
                        closeDialog={() => setCollectionSchemaForCloning(null)}
                        collectionSchemaForCloning={collectionSchemaForCloning}
                        schemaCloningInto={schema}
                    />
                )}
            </Container>
        </Fade>
    );
};
