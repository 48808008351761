import { List, Stack } from '@mui/material';

import { useMetadataCompleteContext } from '../../context/useMetadataContext';
import { CustomFieldCollectionListItem } from './CustomFieldCollectionListItem';

export const CustomFieldsCompletionCollectionView = () => {
    const { collectionSchemas, collectionValues } = useMetadataCompleteContext();

    if (!collectionSchemas.length) return null;

    // TODO: calculate the required items to complete

    return (
        <List component={Stack} spacing={1}>
            {collectionSchemas.map(schema => {
                const collectionValue = collectionValues.get(schema._id);
                const itemHasValueDictionary = Object.keys(schema.items).reduce(
                    (acc, itemSchemaKeyString) => {
                        const itemSchemaKey = Number(itemSchemaKeyString);
                        if (isNaN(itemSchemaKey)) return acc;

                        acc[itemSchemaKey] = collectionValue?.values[itemSchemaKey] !== undefined;
                        return acc;
                    },
                    {} as { [itemSchemaKey: number]: boolean },
                );

                return <CustomFieldCollectionListItem key={schema._id} schema={schema} itemHasValueDictionary={itemHasValueDictionary} />;
            })}
        </List>
    );
};
